import { ThemeConfig, extendTheme } from "@chakra-ui/react";
// import { ButtonStyles as Button } from "./Button/ButtonStyles";
// import { InputStyles as Input } from "./Input/InputStyles";

const config: ThemeConfig = {
  initialColorMode: "light",
  useSystemColorMode: true,
};

const breakpoints = {
  sm: "31em",
  md: "60em",
  lg: "70em",
  xl: "80em",
};

export const theme = extendTheme({
  colors: {
    black: "#2D3748",
    success: "#38A169",
    light_success: "#C6F6D5",
    dark_success: "#276749",
    primary_button: "#1c7499cc",
    primary_button_hover: "#6FAB3B",
    red_button: "#DD2828",
    red_button_hover: "#7E1717",
    input_placeholder_color: "#E5DAC3",
    input_border_color: "#1C7499",
    input_inactive_border_color: "hsl(0, 0%, 80%)",
    avatar_login_color: "#88C453",
    background_color: "#ffffff",
    secondary_background_color: "#1C7499",
    divider_color: "#CECECE",
    nav_links_hover_color: "#62816D",
    language_selector_border_color: "#1C7499",
    language_selector_hover_color: "#88C453",
    general_hover_color: "#A6C7AF",
    language_selector_selected_option_color: "#1C7499",
    secondary_text_color: "#1D3A2A",
    grey_text_color: "#6B7280",
    profile_image_picker: "#1D3A2A",
    table_color: "rgba(29, 58, 42, 0.4)",
    spinner_color: "#fff",
    tooltip_color: "#1D3A2A",
    attachment_upload_background: "#fff",
    attachment_upload_background_hover: "#BFEB99",
    attachment_upload_border: "#88C453",
    file_types_color: "#88C453",
    drawer_btn_color: "#fff",
    primary_color: "#1C7499",
    secondary_color: "#88C453",
    delete_color: "#C45252",
    primary: {
      50: "#00000015",
      500: "#000",
      600: "#333",
    },
    secondary: {
      50: "#ebebeb",
      500: "#ebebeb",
      600: "#d1d1d1",
    },
    accent: {
      50: "#EDF2F7",
      500: "#EDF2F7",
      600: "#CEDBE9",
    },
    side_bar_background: "#EEE",
    white: "#FFFFFF",
    light_text: "rgba(255, 255, 255, 0.8)",
    fade_text: "rgba(255, 255, 255, 0.36)",
    dark_text: "#9E9E9E",
    placeholder_color: "rgba(0, 0, 0, 0.6)",
  },
  components: {
    // Button,
    // Input,
  },
  config,
  breakpoints,
});
