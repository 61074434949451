import { faCirclePlus, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./AddFeatures.scss";
import { IFeatures } from "../../../../../types/features";
import Select from "react-select";
import { useState } from "react";
import Input from "../../../../layouts/Input";
import { keys, omit, pullAt } from "lodash";
import { Button, FormLabel } from "@chakra-ui/react";

interface IAddFeatures {
  translator: any;
  features: IFeatures[];
  baseLang: string;
  values: any;
  setFieldValue: any;
  errors_fmk: any;
  setErrors: Function;
}

interface IFeatureItem {
  baseLang: string;
  features: IFeatures[];
  translator: any;
  index: number;
  values: any;
  setFieldValue: any;
  setFeatureItems: Function;
  isSelectedDisabled: boolean;
  featureItems: any;
  errors_fmk: any;
  setErrors: Function;
}

function FeatureItem(props: IFeatureItem) {
  const {
    baseLang,
    features,
    translator,
    index,
    values,
    setFieldValue,
    setFeatureItems,
    isSelectedDisabled,
    featureItems,
    errors_fmk,
    setErrors,
  } = props;

  const findCurrValue = (id) => {
    const value = features.find((f) => {
      return f.id === id;
    });
    return value
      ? {
          label: baseLang === "es" ? value!.name : value!.name_en,
          value: value!.id,
        }
      : null;
  };
  const findCurrValueInput = (id, selectedFeatures) => {
    const value = features.find((f) => {
      return f.id === id;
    });
    return value ? selectedFeatures[id] : "";
  };
  const findCurrValueInputIcon = (id) => {
    const value = features.find((f) => {
      return f.id === id;
    });
    return value ? value.icon : "fa-solid fa-question";
  };

  return (
    <div className="feature-item">
      <div className="feature-type">
        <Select
          classNamePrefix={"new-select"}
          value={findCurrValue(featureItems[index])}
          isDisabled={isSelectedDisabled}
          onChange={(e: any) => {
            setFieldValue(`selectedFeatures[${e.value}]`, 0);
            setFeatureItems((prev) => {
              const aux = [...prev];
              aux[index] = e.value;
              return aux;
            });
            setErrors({
              ...errors_fmk,
              [`amount_${index}`]: translator("Global.requieredField"),
            });
          }}
          options={features
            .filter((ff) => !keys(values.selectedFeatures).includes(ff.id))
            .map((f: IFeatures, index, _) => {
              return {
                label: baseLang === "es" ? f.name : f.name_en,
                value: f.id,
              };
            })}
        />
      </div>
      <Input
        errors={errors_fmk}
        onChange={(e) => {
          const value = e.currentTarget.value;

          const featureId = featureItems[index];
          setFieldValue(`selectedFeatures[${featureId}]`, value);
          if (value.length === 0) {
            setErrors({
              ...errors_fmk,
              [`amount_${index}`]: translator("Global.requieredField"),
            });
          } else if (isNaN(Number(value))) {
            setErrors({
              ...errors_fmk,
              [`amount_${index}`]: translator("Global.invalidValue"),
            });
          } else {
            setErrors(omit(errors_fmk, `amount_${index}`));
          }
        }}
        label=""
        name={`amount_${index}`}
        placeholder={translator("Global.amount")}
        notShowLabel
        translator={translator}
        value={findCurrValueInput(featureItems[index], values.selectedFeatures)}
        leftChild={
          <i className={`${findCurrValueInputIcon(featureItems[index])}`} />
        }
      />
      <div
        className="remove-icon"
        onClick={() => {
          const featureId = featureItems[index];
          if (featureId) {
            const omitValue = omit(values.selectedFeatures, [featureId]);
            setFieldValue("selectedFeatures", omitValue);
          }
          const auxFeatureItems = [...featureItems];
          pullAt(auxFeatureItems, [index]);
          setFeatureItems(auxFeatureItems);
          setErrors(omit(errors_fmk, `amount_${index}`));
        }}
      >
        <FontAwesomeIcon icon={faTrashCan} />
      </div>
    </div>
  );
}

function AddFeatures(props: IAddFeatures) {
  // const [featureItems, setFeaturesItems] = useState<any>([]);

  const {
    translator,
    features,
    baseLang,
    values,
    setFieldValue,
    errors_fmk,
    setErrors,
  } = props;
  const [featureItems, setFeaturesItems] = useState<any>(
    keys(values.selectedFeatures).map((key) => {
      return key;
    })
  );

  return (
    <div className="features-container">
      <div className="all-features">
        <FormLabel fontWeight={"600"}>
          {translator("Global.features")}
        </FormLabel>
        {featureItems.map((fi, i) => {
          return (
            <FeatureItem
              features={features}
              baseLang={baseLang}
              translator={translator}
              index={i}
              values={values}
              setFieldValue={setFieldValue}
              setFeatureItems={setFeaturesItems}
              isSelectedDisabled={!!fi}
              featureItems={featureItems}
              errors_fmk={errors_fmk}
              setErrors={setErrors}
            />
          );
        })}
      </div>
      {featureItems.length < features.length && (
        <div>
          <Button
            onClick={() => {
              setFeaturesItems([...featureItems, undefined]);
            }}
            className="add-new-feature"
          >
            <FontAwesomeIcon icon={faCirclePlus} />
            <p className="addFeature-label">
              {translator("AdmProperties.addFeature")}
            </p>
          </Button>
        </div>
      )}
    </div>
  );
}

export default AddFeatures;
