/* eslint-disable jsx-a11y/anchor-is-valid */
import { useNavigate } from "react-router-dom";
import { Img, useColorMode } from "@chakra-ui/react";
import "./NavBar.scss";
import LanguageSelector from "../LanguageSelector/LanguageSelector";
import { useState } from "react";
import ProfileDropdown from "../ProfileDropdown/";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { IUser } from "../../../types/user";
import { changeApplanguage } from "../../../redux/reducers/appSettingsSlice";
import useIsMobile from "../../../hooks/useIsMobile";
import useIsAdminMode from "../../../hooks/useIsAdminMode";

interface NavBarProps {
  translator: any;
}
function CCNavBar(props: NavBarProps) {
  const { translator } = props;
  const [baseLanguage, setBaseLanguage] = useState(
    useAppSelector((state) => state.appSettings.language)
  );
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const user: IUser = useAppSelector((state) => state.session.user)!;
  const dispatch = useAppDispatch();
  const { colorMode } = useColorMode();
  const isLightMode = colorMode === "light";
  const isMobile = useIsMobile();
  const isAdminMode = useIsAdminMode(window.location.pathname);

  const onLanguageChange = async (language) => {
    setBaseLanguage(language);
    dispatch(changeApplanguage(language));
    setLoading(false);
  };

  return (
    isAdminMode &&
    window.location.pathname !== "/login" && (
      <header
        className={`navbar-header ${
          isLightMode ? "light-shadow" : "dark-shadow"
        }`}
      >
        <div className="links-base">
          <div className="logo">
            <a onClick={() => navigate("/adm/properties")} className="logo">
              <Img
                borderRadius="full"
                boxSize="72px"
                minWidth={"72px"}
                src={require("../../../assets/images/logo.png")}
              ></Img>
            </a>
          </div>
        </div>
        <div className="user-options">
          {!isMobile && (
            <div className="language_selectror_container">
              <LanguageSelector
                value={baseLanguage}
                isLoading={loading}
                onChange={(language) => {
                  setLoading(true);
                  onLanguageChange(language);
                }}
              ></LanguageSelector>
            </div>
          )}

          <ProfileDropdown
            translator={translator}
            user={user}
          ></ProfileDropdown>
        </div>
      </header>
    )
  );
}
export default CCNavBar;
