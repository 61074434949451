import React, { useMemo, useState } from "react";
import Select, { components, ControlProps, OptionProps } from "react-select";
import "./LanguageSelector.scss";

import flags from "./Flags";
import { useColorMode } from "@chakra-ui/react";

export type TLanguage = "en" | "es";

export interface TLanguageSelectorOption {
  value: TLanguage;
  label: string;
}

export const languageOptions: TLanguageSelectorOption[] = [
  { value: "en", label: "English (US)" },
  { value: "es", label: "Español (CR)" },
];

export interface ILanguageSelectorProps {
  value?: string;
  onChange?: (value: TLanguageSelectorOption["value"]) => void;
  unsupportedLanguages?: Array<TLanguage>;
  isLoading?: boolean;
}

const LanguageSelector = (props: ILanguageSelectorProps) => {
  const { value, onChange, unsupportedLanguages, isLoading } = props;
  const [isOpen, setIsOpen] = useState(false);

  const options = useMemo(
    () =>
      languageOptions.filter(
        (option) => !unsupportedLanguages?.includes(option.value)
      ),
    [unsupportedLanguages]
  );

  const selectedOption = useMemo(
    () => languageOptions.find((option) => option.value === value),
    [value]
  );

  const { colorMode } = useColorMode();
  const isLightMode = colorMode === "light";

  return (
    <Select
      className={`${
        isLightMode ? "language-selector" : "dark-language-selector"
      }
        ${isOpen ? "menu-is-open" : ""}
        ${isLoading ? "is-loading" : ""}
      `}
      classNamePrefix={`${
        isLightMode ? "language-selector" : "dark-language-selector"
      }`}
      options={options}
      value={selectedOption || languageOptions[1]}
      isSearchable={false}
      menuIsOpen={isOpen}
      onMenuOpen={() => !isLoading && setIsOpen(true)}
      onMenuClose={() => setIsOpen(false)}
      onChange={(option) => option?.value && onChange?.(option.value)}
      maxMenuHeight={100000}
      components={{
        Option: LanguageOption,
        Control: LanguageSelectorControl(isLoading),
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
      }}
    />
  );
};

const LanguageSelectorControl =
  (isLoading?: boolean) =>
  ({ children, ...props }: ControlProps<TLanguageSelectorOption, false>) => {
    const [option] = props.getValue();
    return (
      <components.Control {...props}>
        {option && !isLoading ? (
          <OptionWithFlag lang={option.value}>{children}</OptionWithFlag>
        ) : null}
      </components.Control>
    );
  };

const LanguageOption = (props: OptionProps<TLanguageSelectorOption, false>) => {
  return (
    <components.Option {...props}>
      <OptionWithFlag lang={props.data.value}>{props.label}</OptionWithFlag>
    </components.Option>
  );
};

const OptionWithFlag = ({ lang, children }) => {
  return (
    <>
      <div className="lang-flag">{flags[lang]}</div>
      <div className="lang-name">{children}</div>
    </>
  );
};

export default LanguageSelector;
