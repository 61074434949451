/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { Avatar } from "@chakra-ui/react";
import "./Login.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightToBracket } from "@fortawesome/free-solid-svg-icons";

import CCButton from "../../layouts/Button";
import CCInput from "../../layouts/Input";
import { getUImage, login } from "../../../providers/authContextProvider";
import { Formik } from "formik";
import useTranslations from "../../../hooks/useTranslations";
import { isJWTValid } from "../../../utils/jwtUtils";
import { showToast } from "../../../utils/toast";
import * as yup from "yup";

interface ILogin {}

const LogIn: React.FC<ILogin> = (props) => {
  const appUser = useAppSelector((state) => state.session.user);
  const token = useAppSelector((state) => state.session.token);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const translator = useTranslations();

  useEffect(() => {
    // setColorMode("light");
    if (appUser && isJWTValid(token)) navigate("/adm/properties");
    appUser && getUImage(dispatch, appUser?.id);
  }, [appUser]);

  const initialValues = {
    id: "",
    password: "",
  };

  const validationSchema = yup.object().shape({
    id: yup.string().required(translator("Global.requieredField")),
    password: yup.string().required(translator("Global.requieredField")),
  });

  return (
    <div className="login-base">
      <div className="left-side">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            const loginInformation = {
              id: values.id,
              password: values.password,
            };
            login(dispatch, translator, loginInformation).then((isUser) => {
              if (isUser) {
                navigate("/adm/properties");
              } else {
                showToast(
                  translator("ToastMessages.noValid"),
                  translator("ToastMessages.noValidUsernamePassword"),
                  "error"
                );
              }
            });
          }}
        >
          {(props) => (
            <div className="form">
              <Avatar className="avatar-img" size={"xl"} />
              <CCInput
                label={translator("Login.id")}
                placeholder={translator("Login.id")}
                translator={translator}
                value={props.values.id}
                validationSchema={validationSchema}
                errors={props.errors}
                name="id"
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    props.submitForm();
                  }
                }}
                onChange={(e) => {
                  props.setFieldValue("id", e.currentTarget.value);
                }}
              ></CCInput>
              <CCInput
                label={translator("Login.password")}
                placeholder={translator("Login.password")}
                type="password"
                translator={translator}
                value={props.values.password}
                validationSchema={validationSchema}
                errors={props.errors}
                name="password"
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    props.submitForm();
                  }
                }}
                onChange={(e) => {
                  props.setFieldValue("password", e.currentTarget.value);
                }}
              ></CCInput>
              <CCButton
                className="login-button"
                rightIcon={<FontAwesomeIcon icon={faRightToBracket} />}
                onClick={props.submitForm}
              >
                {translator("Login.login")}
              </CCButton>
            </div>
          )}
        </Formik>
      </div>
      <div
        className="right-side"
        style={{ backgroundImage: `url(/banner.jpeg)` }}
      >
        <div className="logo-container">
          <img
            className="logo"
            src={require("../../../assets/images/logo2.png")}
            alt="logo"
          />
        </div>
      </div>
    </div>
  );
};

export default LogIn;
