/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import useTranslations from "../../../../hooks/useTranslations";
// import Select from "../../../layouts/Select";
import "./Properties.scss";
import locations from "../../../../assets/locations/locations.json";
import { get, keys } from "lodash";
import { Formik, useFormikContext } from "formik";
import Input from "../../../layouts/Input";
import CCButton from "../../../layouts/Button";
import NewProperty from "../NewPropertyModal";
import { useEffect, useMemo, useRef, useState } from "react";
import IProperty from "../../../../types/property";
import {
  getProperties,
  updateProperty,
} from "../../../../services/PropertiesService";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { useNavigate } from "react-router-dom";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Card,
  CardFooter,
  FormControl,
  FormLabel,
  Heading,
  Image,
  Stack,
} from "@chakra-ui/react";
import { CardBody, Col, Row } from "reactstrap";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { mapsStyle } from "../../../../styles/maps";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCopy,
  faFilterCircleXmark,
  faLocationDot,
  faPenToSquare,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import { showToast } from "../../../../utils/toast";
import { changeIsLoading } from "../../../../redux/reducers/appSettingsSlice";
import { getCurrencyFormat } from "../../../../utils/digitsUtils";
import Pagination from "../../../common/Pagination";
import { parseCurrentPageItems } from "../../../../utils/common";

interface IPropertyCard {
  translator: any;
  property: IProperty;
  baseLanguage: any;
  setSelectedProperty: Function;
  setIsNewPropertyOpen: Function;
  userToken: any;
  navigate: Function;
  getPropertiesData: Function;
  toogleIsLoading: Function;
}

function PropertyCard(props: IPropertyCard) {
  const {
    property,
    setIsNewPropertyOpen,
    setSelectedProperty,
    baseLanguage,
    translator,
    userToken,
    navigate,
    getPropertiesData,
    toogleIsLoading,
  } = props;
  const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState<boolean>(false);
  const cancelDeleteRef = useRef<any>();
  const CONSECUTIVE_MAX_LENGTH = 6;

  const getImages = () => {
    if (property.files) {
      const files = property.files.filter((file: any) => {
        let include = false;
        if (file.name.split(".")[1] === "jpg") include = true;
        if (file.name.split(".")[1] === "jpeg") include = true;
        if (file.name.split(".")[1] === "png") include = true;
        return include;
      });
      return files;
    }
    return [];
  };
  const getLocation = () => {
    const values = property.location.split(",");
    const lat = Number(values[0]);
    const lng = Number(values[1]);
    return { lat, lng };
  };

  const getRefKey = (propertyConsecutive) => {
    const consecutiveLength = String(propertyConsecutive).length;
    return `PRO-${"0".repeat(
      CONSECUTIVE_MAX_LENGTH - consecutiveLength
    )}${propertyConsecutive}`;
  };

  return (
    <>
      <AlertDialog
        isOpen={isDeleteAlertOpen}
        leastDestructiveRef={cancelDeleteRef}
        onClose={() => {
          setIsDeleteAlertOpen(false);
        }}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {translator("NewProperty.deleteProperty")}
            </AlertDialogHeader>

            <AlertDialogBody>
              {translator("NewProperty.deletePropertyMessage")}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                variant={"solid"}
                ref={cancelDeleteRef}
                onClick={() => {
                  setIsDeleteAlertOpen(false);
                }}
              >
                {translator("Global.cancel")}
              </Button>
              <Button
                colorScheme="red"
                onClick={async () => {
                  toogleIsLoading(true);
                  const auxProperty = { ...property, status: "deleted" };
                  const status = await updateProperty(
                    auxProperty,
                    translator,
                    false,
                    userToken,
                    navigate
                  );
                  showToast(
                    status
                      ? translator("ToastMessages.success")
                      : translator("ToastMessages.error"),
                    status
                      ? translator("ToastMessages.genericDeleteSuccess")
                      : translator("ToastMessages.genericDeleteError"),
                    "success"
                  );
                  getPropertiesData();
                }}
                ml={3}
              >
                {translator("Global.delete")}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <div className="card-container">
        <Card maxW="sm" width={"full"}>
          <CardBody>
            {getImages().length > 0 ? (
              <div className="cover-container">
                {(property.sold || !property.visible) && (
                  <div
                    className={`stamp-base ${
                      property.soldByUs ? "stamp-base-sc" : ""
                    }`}
                  >
                    <span
                      className={`stamp ${
                        property.sold && !property.soldByUs ? "sold" : ""
                      } ${
                        property.sold && property.soldByUs ? "soldByUs" : ""
                      } ${
                        !property.visible && !property.sold ? "invisible-s" : ""
                      }`}
                    >
                      {property.sold
                        ? translator("Global.sold")
                        : !property.visible
                        ? translator("Global.hidden")
                        : ""}
                    </span>
                  </div>
                )}
                <Image
                  className="property-image"
                  src={getImages()[0].url}
                  borderRadius="lg"
                />
              </div>
            ) : (
              <div className="cover-container">
                {(property.sold || !property.visible) && (
                  <div
                    className={`stamp-base ${
                      property.soldByUs ? "stamp-base-sc" : ""
                    }`}
                  >
                    <span
                      className={`stamp ${
                        property.sold && !property.soldByUs ? "sold" : ""
                      } ${
                        property.sold && property.soldByUs ? "soldByUs" : ""
                      }${
                        !property.visible && !property.sold ? "invisible-s" : ""
                      }`}
                    >
                      {property.sold
                        ? translator("Global.sold")
                        : !property.visible
                        ? translator("Global.hidden")
                        : ""}
                    </span>
                  </div>
                )}
                <div className="map-base-property-card">
                  <GoogleMap
                    zoom={15}
                    center={getLocation()}
                    mapContainerClassName="map-container"
                    options={{
                      gestureHandling: "none",
                      fullscreenControl: false,
                      zoomControl: false,
                      mapTypeControl: false,
                      scaleControl: false,
                      streetViewControl: false,
                      rotateControl: false,
                      styles: mapsStyle,
                      clickableIcons: false,
                    }}
                  >
                    <Marker position={getLocation()} />
                  </GoogleMap>
                </div>
              </div>
            )}
            <Stack mt="6" padding={"10px"} spacing="3">
              <Heading size="md">
                {baseLanguage === "es" ? property.name : property.nameEn}
              </Heading>
              <div
                onClick={() => {
                  navigator.clipboard.writeText(
                    getRefKey(property.consecutive)
                  );
                  showToast(
                    translator("ToastMessages.success"),
                    translator("ToastMessages.consecutiveCopied"),
                    "info"
                  );
                }}
                className="ref-key"
              >
                {`Nº Ref: `}
                {getRefKey(property.consecutive)}
                <FontAwesomeIcon icon={faCopy} />
              </div>
              <Row>
                <Col className="card-subtitle" xl={"6"}>{`${translator(
                  "Global.price"
                )}: `}</Col>
                <Col xl={"6"}>{`${getCurrencyFormat(
                  "es-CR",
                  "CRC",
                  property.price
                )}`}</Col>
              </Row>
              <Row>
                <Col className="card-subtitle" xl={"6"}>{`${translator(
                  "Global.type"
                )}: `}</Col>
                <Col xl={"6"}>{`${
                  property.type === "LAND"
                    ? translator("Global.land")
                    : property.type === "BUILD"
                    ? translator("Global.construction")
                    : property.type === "LAND-BUILD"
                    ? translator("Global.landConst")
                    : ""
                }`}</Col>
              </Row>
              <Row>
                <Col className="card-subtitle" xl={"6"}>{`${translator(
                  "NewProperty.seller"
                )}: `}</Col>
                <Col xl={"6"}>{`${property.seller}`}</Col>
              </Row>
              <Row>
                <Col className="card-subtitle" xl={"6"}>{`${translator(
                  "Global.phoneNumber"
                )}: `}</Col>
                <Col xl={"6"}>{`${property.sellerPhone}`}</Col>
              </Row>
            </Stack>
          </CardBody>
          <CardFooter
            justify="space-between"
            flexWrap="wrap"
            sx={{
              "& > button": {
                minW: "136px",
              },
            }}
          >
            <Button
              flex="1"
              variant="ghost"
              leftIcon={<FontAwesomeIcon icon={faTrashCan} />}
              onClick={async () => {
                setIsDeleteAlertOpen(true);
              }}
            >
              {translator("Global.delete")}
            </Button>
            <Button
              flex="1"
              variant="ghost"
              leftIcon={<FontAwesomeIcon icon={faPenToSquare} />}
              onClick={() => {
                setSelectedProperty(property);
                setIsNewPropertyOpen(true);
              }}
            >
              {translator("Global.edit")}
            </Button>
          </CardFooter>
        </Card>
      </div>
    </>
  );
}

function AdmProperties() {
  const [isNewPropertyOpen, setIsNewPropertyOpen] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState<IProperty | null>(
    null
  );
  const [propertiesList, setPropertiesList] = useState<IProperty[]>([]);
  const [propertiesListFiltered, setPropertiesListFiltered] = useState<
    IProperty[]
  >([]);
  const [filteredCount, setFilteredCount] = useState<number>(
    propertiesList.length
  );
  const [currentPage, setCurrentPage] = useState<number>(0);
  const itemsPerPage = 8;

  const baseLanguage = useAppSelector((state) => state.appSettings.language);
  const translator = useTranslations();
  const provinces = get(locations, "provinces");
  const dispatch = useAppDispatch();
  const initialValues = {
    nameRef: "",
    province: null,
    canton: null,
    district: null,
    type: null,
    status: null,
  };

  const userToken = useAppSelector((state) => state.session.token)!;
  const navigate = useNavigate();

  const getPropertiesData = async () => {
    toogleIsLoading(true);
    const data: IProperty[] = await getProperties(
      translator,
      userToken,
      navigate
    )!;
    setPropertiesList(data);
    setPropertiesListFiltered(data);
    toogleIsLoading(false);
  };

  const filterProperties = (values) => {
    const filteredInformation = propertiesList.filter(
      (property) =>
        filterByKey("nameRef", property, values) &&
        filterByKey("province", property, values) &&
        filterByKey("canton", property, values) &&
        filterByKey("district", property, values) &&
        filterByKey("type", property, values) &&
        filterByKey("status", property, values)
    );
    setPropertiesListFiltered(filteredInformation);
  };

  const filterByKey = (keyName, property, values) => {
    if (!values[keyName] || values[keyName] === "") {
      return true;
    }
    if (keyName === "nameRef") {
      const formikValue = values[keyName];
      return (
        property["name"].includes(formikValue) ||
        property["consecutive"] === Number(formikValue.split("-")[1])
      );
    }
    if (keyName === "status") {
      const formikValue = values[keyName].value;
      return formikValue === "SOLD"
        ? property["sold"]
        : formikValue === "HIDDEN"
        ? !property["visible"] && !property["sold"]
        : false;
    }
    return property[keyName] === values[keyName].value;
  };

  const toogleIsLoading = (isLoading) => {
    dispatch(changeIsLoading(isLoading));
  };
  const handleOnPageChange = (pageNumber: number, itemsPerPage: number) => {
    setCurrentPage(pageNumber);
  };

  const items: any = useMemo(() => {
    const auxItems = [...propertiesListFiltered];
    setFilteredCount(auxItems.length);
    if (propertiesList.length !== auxItems.length) {
      return auxItems;
    }
    return parseCurrentPageItems(auxItems, currentPage, itemsPerPage);
  }, [propertiesList, propertiesListFiltered, currentPage, itemsPerPage]);

  const checkRenderPagination = () =>
    propertiesList.length > itemsPerPage &&
    propertiesList.length === filteredCount &&
    filteredCount > itemsPerPage;

  useEffect(() => {
    getPropertiesData();
  }, []);

  return (
    <>
      <NewProperty
        translator={translator}
        isOpen={isNewPropertyOpen}
        setIsOpen={setIsNewPropertyOpen}
        selectedProperty={selectedProperty}
        setSelectedProperty={setSelectedProperty}
        getPropertiesData={getPropertiesData}
      />
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          filterProperties(values);
        }}
      >
        {({ values, setFieldValue, resetForm, submitForm }) => {
          return (
            <div className="filter-bar">
              {/* <OnValuesChange /> */}
              <div className="btn-container">
                <CCButton
                  rightIcon={<FontAwesomeIcon icon={faLocationDot} />}
                  onClick={() => setIsNewPropertyOpen(true)}
                >
                  {translator("AdmProperties.newProperty")}
                </CCButton>
              </div>
              <h4>{translator("AdmProperties.filters")}</h4>
              <div className="filters-base">
                <div className="input-container">
                  <Input
                    label={translator("AdmProperties.nameRef")}
                    value={values.nameRef}
                    translator={translator}
                    onChange={(e) => {
                      setFieldValue("nameRef", e.currentTarget.value);
                      submitForm();
                    }}
                  />
                </div>
                <div className="input-container">
                  <FormControl>
                    <FormLabel fontWeight={"600"}>
                      {translator("Global.type")}
                    </FormLabel>
                    <Select
                      classNamePrefix={"new-select"}
                      value={values.type}
                      options={[
                        {
                          value: "LAND",
                          label: translator("Global.land"),
                        },
                        {
                          value: "BUILD",
                          label: translator("Global.construction"),
                        },
                        {
                          value: "LAND-BUILD",
                          label: translator("Global.landConst"),
                        },
                      ]}
                      onChange={(option) => {
                        setFieldValue("type", option);
                        submitForm();
                      }}
                    />
                  </FormControl>
                </div>
                <div className="input-container">
                  <FormControl>
                    <FormLabel fontWeight={"600"}>
                      {translator("Global.province")}
                    </FormLabel>
                    <Select
                      value={values.province}
                      classNamePrefix={"new-select"}
                      onChange={(e) => {
                        setFieldValue("canton", null);
                        setFieldValue("district", null);
                        setFieldValue("province", e);
                        submitForm();
                      }}
                      options={[
                        ...keys(provinces).map((p) => {
                          const province = provinces[p];
                          return { value: p, label: province.name };
                        }),
                      ]}
                    />
                  </FormControl>
                </div>
                <div className="input-container">
                  <FormControl>
                    <FormLabel fontWeight={"600"}>
                      {translator("Global.canton")}
                    </FormLabel>
                    <Select
                      classNamePrefix={"new-select"}
                      value={values.canton}
                      onChange={(e) => {
                        setFieldValue("district", null);
                        setFieldValue("canton", e);
                        submitForm();
                      }}
                      options={
                        get(values.province, "value") !== ""
                          ? [
                              ...keys(
                                get(
                                  locations,
                                  `provinces.${get(
                                    values.province,
                                    "value"
                                  )}.cantons`
                                )
                              ).map((c) => {
                                const canton: any = get(
                                  locations,
                                  `provinces.${get(
                                    values.province,
                                    "value"
                                  )}.cantons`
                                )![c];
                                return {
                                  value: c,
                                  label: canton.name,
                                };
                              }),
                            ]
                          : []
                      }
                    />
                  </FormControl>
                </div>
                <div className="input-container">
                  <FormControl>
                    <FormLabel fontWeight={"600"}>
                      {translator("Global.district")}
                    </FormLabel>
                    <Select
                      classNamePrefix={"new-select"}
                      value={values.district}
                      onChange={(e) => {
                        setFieldValue("district", e);
                        submitForm();
                      }}
                      options={
                        get(values.canton, "value") !== ""
                          ? [
                              ...keys(
                                get(
                                  locations,
                                  `provinces.${get(
                                    values.province,
                                    "value"
                                  )}.cantons.${get(
                                    values.canton,
                                    "value"
                                  )}.districts`
                                )
                              ).map((c) => {
                                const district: any = get(
                                  locations,
                                  `provinces.${get(
                                    values.province,
                                    "value"
                                  )}.cantons.${get(
                                    values.canton,
                                    "value"
                                  )}.districts`
                                )![c];
                                return { value: c, label: district };
                              }),
                            ]
                          : []
                      }
                    />
                  </FormControl>
                </div>
                <div className="input-container">
                  <FormControl>
                    <FormLabel fontWeight={"600"}>
                      {translator("Global.status")}
                    </FormLabel>
                    <Select
                      classNamePrefix={"new-select"}
                      value={values.status}
                      onChange={(e) => {
                        setFieldValue("status", e);
                        submitForm();
                      }}
                      options={[
                        { value: "SOLD", label: translator("Global.sold") },
                        { value: "HIDDEN", label: translator("Global.hidden") },
                      ]}
                    />
                  </FormControl>
                </div>
                <div className="clear-filters-container">
                  <CCButton
                    rightIcon={<FontAwesomeIcon icon={faFilterCircleXmark} />}
                    onClick={() => {
                      resetForm();
                      submitForm();
                    }}
                  >
                    {translator("AdmProperties.clearFilters")}
                  </CCButton>
                </div>
              </div>
            </div>
          );
        }}
      </Formik>
      <div className="adm-properties-container">
        <div className="properties-list">
          {items.map((property: IProperty) => {
            return (
              <PropertyCard
                translator={translator}
                baseLanguage={baseLanguage}
                setIsNewPropertyOpen={setIsNewPropertyOpen}
                setSelectedProperty={setSelectedProperty}
                property={property}
                userToken={userToken}
                navigate={navigate}
                getPropertiesData={getPropertiesData}
                toogleIsLoading={toogleIsLoading}
              />
            );
          })}
        </div>
      </div>
      <div className="pagination-container">
        {checkRenderPagination() && (
          <Pagination
            totalCount={propertiesList.length}
            itemsPerPage={itemsPerPage}
            handleOnPageChange={handleOnPageChange}
            currentPage={currentPage}
            filteredCount={propertiesListFiltered.length}
          />
        )}
      </div>
    </>
  );
}

export default AdmProperties;
