import * as React from "react";
import { Navigate } from "react-router-dom";
import { useAppSelector } from "../../../redux/hooks";
import { isJWTValid } from "../../../utils/jwtUtils";

interface Props {
  component: any;
}

const AuthRoute = ({ component: Component, ...rest }: Props) => {
  const appUser = useAppSelector((state) => state.session.user);
  const token = useAppSelector((state) => state.session.token);
  return appUser && isJWTValid(token) ? (
    <Component {...rest} />
  ) : (
    <Navigate to="/login" />
  );
};

export default AuthRoute;
