import { Route, BrowserRouter, Routes } from "react-router-dom";
import React from "react";

import "./app.scss";
import Home from "./components/views/Home";
import Contact from "./components/views/Contact";
import About from "./components/views/About";
import NavbarPub from "./components/layouts/NavBarPub";
import Navbar from "./components/layouts/NavBar";
import useTranslations from "./hooks/useTranslations";
import Footer from "./components/layouts/Footer";
import Login from "./components/views/Login";
import AuthRoute from "./components/common/AuthRoute";
import AdmProperties from "./components/views/Adm/Properties";
import Spinner from "./components/layouts/Spinner";
import { useAppDispatch, useAppSelector } from "./redux/hooks";
import { useLoadScript } from "@react-google-maps/api";
import { changeIsMapLoaded } from "./redux/reducers/appSettingsSlice";
import ValidatePin from "./components/views/Profile/validatePin";
import Property from "./components/views/Property";
import Properties from "./components/views/Properties";
import Favorites from "./components/views/Favorites";

function App() {
  const translator = useTranslations();
  const isLoading = useAppSelector((state) => state.appSettings.isLoading);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API!,
    libraries: ["places"],
  });

  const dispatch = useAppDispatch();
  dispatch(changeIsMapLoaded(isLoaded));

  return (
    <div className="App">
      <BrowserRouter>
        {isLoading && (
          <div className="loading-info">
            <Spinner label={translator("Global.loading")}></Spinner>
          </div>
        )}
        <NavbarPub translator={translator} />
        <Navbar translator={translator} />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/properties" element={<Properties />} />
          <Route path="/favorites" element={<Favorites />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<About />} />
          <Route path="/login" element={<Login />} />
          <Route path="/property/:propertyId" element={<Property />} />
          <Route
            path="/validatePin"
            element={<AuthRoute component={ValidatePin} />}
          />
          <Route
            path="/adm/properties"
            element={<AuthRoute component={AdmProperties} />}
          />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
