import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUser } from "../../types/user";

interface usersState {
  users: IUser[] | [];
}

const initialState: usersState = {
  users: [],
};

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    addUsers: (state, { payload }: PayloadAction<IUser[]>) => {
      state.users = payload;
    },
  },
});

export const { addUsers } = usersSlice.actions;

export default usersSlice.reducer;
