import { Img } from "@chakra-ui/react";
import useTranslations from "../../../hooks/useTranslations";
import "./About.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera, faGlobe, faIcons } from "@fortawesome/free-solid-svg-icons";

interface IServicesProps {
  label: string;
  icon: any;
}

function Service(props: IServicesProps) {
  return (
    <div className="service">
      <div className="icon-container">{props.icon}</div>
      <p className="service-label">{props.label}</p>
    </div>
  );
}

function About() {
  const translator = useTranslations();

  return (
    <div>
      <div className="about-container">
        <div className="mision-vision-container">
          <div className="image-container right">
            <Img src={require("../../../assets/images/about_1.jpg")}></Img>
          </div>
          <div className="description-container over-margin">
            <p className="title">{translator("About.mision")}</p>
            <div
              dangerouslySetInnerHTML={{
                __html: translator("About.misionDescription"),
              }}
            ></div>
            <Img
              className="mision-vision-end-icon"
              src={require("../../../assets/images/arrow.png")}
            ></Img>
          </div>
        </div>
        <div className="mision-vision-container over-margin mobile-order">
          <div className="description-container vision-margin">
            <p className="title">{translator("About.vision")}</p>
            <div
              dangerouslySetInnerHTML={{
                __html: translator("About.visionDescription"),
              }}
            ></div>
            <Img
              className="mision-vision-end-icon"
              src={require("../../../assets/images/arrow.png")}
            ></Img>
          </div>
          <div className="image-container left">
            <Img src={require("../../../assets/images/about_2.jpg")}></Img>
          </div>
        </div>
        <div>
          <p className="title">{translator("About.ourServices")}</p>
          <div className="services">
            <Service
              label={translator("About.photography")}
              icon={<FontAwesomeIcon icon={faCamera} />}
            />
            <Service
              label={translator("About.aerialShots")}
              icon={<Img src={require("../../../assets/images/drone.png")} />}
            />
            <Service
              label={translator("About.webPublication")}
              icon={<FontAwesomeIcon icon={faGlobe} />}
            />
            <Service
              label={translator("About.socialsPublication")}
              icon={<FontAwesomeIcon icon={faIcons} />}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
