import ReactPaginate from "react-paginate";
import "./Pagination.scss";
import {
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface IPagination {
  totalCount: number;
  itemsPerPage: number;
  filteredCount: number;
  handleOnPageChange: (currentPage: number, itemsPerPage: number) => void;
  currentPage: number;
}

const Pagination = (props: IPagination) => {
  const {
    totalCount,
    itemsPerPage,
    handleOnPageChange,
    filteredCount,
    currentPage,
  } = props;

  const mainCount = filteredCount === totalCount ? totalCount : filteredCount;
  const pageCount = Math.ceil(mainCount / itemsPerPage);

  const firstPage = currentPage === 0;
  const lastPage = currentPage + 1 === pageCount;

  const onPageChange = (obj: { selected: number }) => {
    handleOnPageChange(obj.selected, itemsPerPage);
  };

  return (
    <div>
      <ReactPaginate
        containerClassName={"pagination_container"}
        breakLabel={"..."}
        breakClassName={"break_label"}
        pageClassName={"item_page"}
        pageCount={pageCount}
        nextLabel={<FontAwesomeIcon icon={faChevronRight} />}
        nextClassName={`${"page_controller"} ${lastPage ? "disabled" : ""}`}
        previousLabel={<FontAwesomeIcon icon={faChevronLeft} />}
        previousClassName={`${"page_controller"} ${
          firstPage ? "disabled" : ""
        }`}
        activeClassName={"active"}
        onPageChange={onPageChange}
        forcePage={currentPage}
      />
    </div>
  );
};

export default Pagination;
