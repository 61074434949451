import * as jose from "jose";

let key;
jose.generateKeyPair("RS256").then(({ privateKey }) => {
  key = privateKey;
});

export const createJWT = async () => {
  const jwt = await new jose.SignJWT({
    app: "SC Real Estate",
    created: "01/03/2024",
    owner: "S&C Real Estate",
  })
    .setProtectedHeader({ typ: "JWT", alg: "RS256" })
    .setIssuedAt()
    .setExpirationTime("2h")
    .sign(key);

  return jwt;
};

export const isJWTValid = (token) => {
  if (token) {
    const { exp } = jose.decodeJwt(token);
    return Date.now() < exp! * 1000;
  }
  return false;
};
