import { AES, enc } from "crypto-js";

const secret = "fsEW_sJxZCGhRgRhpoZnhG9J994vyPnMXKbGrNA3qfA";

export const encrypt = (value: string) => {
  const enc = AES.encrypt(value, secret);
  return enc.toString();
};

export const decrypt = (value: string) => {
  const bytes = AES.decrypt(value, secret);
  const decrypt = bytes.toString(enc.Utf8);
  return decrypt;
};
