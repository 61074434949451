/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
  getPropertyById,
  getVisibleProperties,
} from "../../../services/PropertiesService";
import "./Property.scss";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import useTranslations from "../../../hooks/useTranslations";
import IProperty from "../../../types/property";
import { getCurrencyFormat } from "../../../utils/digitsUtils";
import locations from "../../../assets/locations/locations.json";
import { get, keys, shuffle } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faCircleCheck,
  faCopy,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import ReactPlayer from "react-player";
import { Img } from "@chakra-ui/react";
import "@splidejs/react-splide/css/sea-green";
import { Carousel, CarouselItem, CarouselIndicators } from "reactstrap";
import { showToast } from "../../../utils/toast";
import { IFeatures } from "../../../types/features";
import {
  getAmenities,
  getFeatures,
} from "../../../services/FeaturesAmenitiesService";
import { IAmenities } from "../../../types/amenities";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { mapsStyle } from "../../../styles/maps";
import useIsMobile from "../../../hooks/useIsMobile";
import {
  addFavoritesProperties,
  changeFavoritesProperties,
} from "../../../redux/reducers/appSettingsSlice";
import PropertyCard from "../Properties/PropertyCard";

interface IPropertyComponent {}

function Property(props: IPropertyComponent) {
  const [property, setProperty] = useState<IProperty | undefined>();
  const [nearbyProperties, setNearbyProperties] = useState<IProperty[]>();
  const [activeIndex, setActiveIndex] = useState(0);
  const [features, setFeatures] = useState<IFeatures[]>([]);
  const [amenities, setAmenities] = useState<IAmenities[]>([]);

  const { propertyId } = useParams();
  const navigate = useNavigate();
  const baseLanguage = useAppSelector((state) => state.appSettings.language);
  const translator = useTranslations();
  const isMobile = useIsMobile();
  const favoriteProperties = useAppSelector(
    (state) => state.appSettings.favoritesProperties
  );
  const dispatch = useAppDispatch();

  const CONSECUTIVE_MAX_LENGTH = 6;
  const NERBY_PROPERTIES_KM_DISTANCE = 10;

  const getPropertyData = async () => {
    const propertyData = await getPropertyById(propertyId);

    if (propertyData) {
      setProperty(propertyData);
    } else {
      navigate("/");
    }
  };

  const getPropertiesData = async () => {
    const data: IProperty[] = await getVisibleProperties()!;
    return data;
  };

  const getFeaturesData = async () => {
    const data: IFeatures[] = await getFeatures(translator);
    setFeatures(data);
  };

  const getAmenitiesData = async () => {
    const data: IAmenities[] = await getAmenities(translator);
    setAmenities(data);
  };

  const handleTypeTranslate = (type: string) => {
    switch (type) {
      case "BUILD":
        return translator("Global.construction");
      case "LAND":
        return translator("Global.land");
      case "LAND-BUILD":
        return translator("Global.landConst");
    }
  };

  const getFiles = () => {
    if (property!.files) {
      const files = property!.files.filter((file: any) => {
        let include = false;
        if (file.name.split(".")[1] === "jpg") include = true;
        if (file.name.split(".")[1] === "jpeg") include = true;
        if (file.name.split(".")[1] === "png") include = true;
        if (file.name.split(".")[1] === "mp4") include = true;
        return include;
      });
      return files;
    }
    return [];
  };

  const getRefKey = (propertyConsecutive) => {
    const consecutiveLength = String(propertyConsecutive).length;
    return `PRO-${"0".repeat(
      CONSECUTIVE_MAX_LENGTH - consecutiveLength
    )}${propertyConsecutive}`;
  };

  const getLocation = (propertyValue) => {
    console.log(propertyValue);

    const values = propertyValue?.location.split(",");
    const lat = Number(values[0]);
    const lng = Number(values[1]);
    return { lat, lng };
  };

  const renderSlides = (isThumbs) => {
    return getFiles().map((file, index) => {
      if (file.name.split(".")[1] === "mp4") {
        return (
          <CarouselItem key={index}>
            {!isThumbs ? (
              <div className="player d-block w-100">
                <div className="player-bg">
                  <ReactPlayer
                    width={"100%"}
                    height={isMobile ? "236px" : "500px"}
                    playing={true}
                    muted
                    loop
                    url={file.url}
                  />
                </div>
              </div>
            ) : (
              <Img
                className={"carousel-image"}
                src={require("../../../assets/images/video_source.png")}
              />
            )}
          </CarouselItem>
        );
      }
      return (
        <CarouselItem key={index}>
          <Img className={"carousel-image"} src={file.url} />
        </CarouselItem>
      );
    });
  };

  const next = () => {
    const nextIndex =
      activeIndex === getFiles().length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    const nextIndex =
      activeIndex === 0 ? getFiles().length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    setActiveIndex(newIndex);
  };

  const isFavoriteProperty = !!favoriteProperties?.find((fp) => {
    return fp.id === property?.id;
  });

  const handleFavoriteProperty = () => {
    if (property) {
      if (isFavoriteProperty) {
        dispatch(
          changeFavoritesProperties([
            ...favoriteProperties.filter((fp) => {
              return fp.id !== property?.id;
            }),
          ])
        );
      } else {
        dispatch(addFavoritesProperties(property));
      }
    }
  };

  function degreesToRadians(degrees) {
    return (degrees * Math.PI) / 180;
  }

  function distanceInKmBetweenEarthCoordinates(lat1, lon1, lat2, lon2) {
    var earthRadiusKm = 6371;

    var dLat = degreesToRadians(lat2 - lat1);
    var dLon = degreesToRadians(lon2 - lon1);

    lat1 = degreesToRadians(lat1);
    lat2 = degreesToRadians(lat2);

    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return earthRadiusKm * c;
  }

  const getNearbyProperties = async () => {
    const propertiesList = await getPropertiesData();

    const filteredNerbyProperties = propertiesList.filter((proper) => {
      if (property?.id === proper.id) return false;

      if (proper && property) {
        const visualPropertyLocation = getLocation(property);
        const auxPropertyLocation = getLocation(proper);
        return (
          distanceInKmBetweenEarthCoordinates(
            visualPropertyLocation.lat,
            visualPropertyLocation.lng,
            auxPropertyLocation.lat,
            auxPropertyLocation.lng
          ) <= NERBY_PROPERTIES_KM_DISTANCE
        );
      } else {
        return false;
      }
    });
    if (filteredNerbyProperties?.length! < 4) {
      setNearbyProperties(filteredNerbyProperties);
    } else {
      const auxNearbyProperties = shuffle(filteredNerbyProperties);
      setNearbyProperties([
        auxNearbyProperties[0],
        auxNearbyProperties[1],
        auxNearbyProperties[2],
      ]);
    }
  };

  useEffect(() => {
    getFeaturesData();
    getAmenitiesData();
    getPropertyData();
  }, []);

  useEffect(() => {
    getNearbyProperties();
  }, [property]);

  return property ? (
    <div className="property-container">
      <div className="property-header">
        <div className="pill-favorite">
          <div className="type-pill">{handleTypeTranslate(property!.type)}</div>
          {!property.sold && (
            <div
              onClick={() => {
                handleFavoriteProperty();
              }}
              className="favorite"
            >
              {isFavoriteProperty ? (
                <i className="favorite-icon fa-solid fa-heart"></i>
              ) : (
                <i className="favorite-icon fa-regular fa-heart"></i>
              )}
              {!isMobile && (
                <p className="favorite-label">
                  {!isFavoriteProperty
                    ? translator("Global.addFavorite")
                    : translator("Global.removeFavorite")}
                </p>
              )}
            </div>
          )}
        </div>
        <div className="property-name-status">
          <div className="property-name">
            <p>{baseLanguage === "es" ? property?.name : property?.nameEn}</p>
          </div>
          {property.soldByUs && (
            <div className="property-status">{translator("Global.sold")}</div>
          )}
        </div>
        <div className="property-location">
          <FontAwesomeIcon icon={faLocationDot} />
          <p>{`${get(
            locations,
            `provinces.${property.province}.name`,
            ""
          )}, ${get(
            locations,
            `provinces.${property.province}.cantons.${property.canton}.name`,
            ""
          )}, ${get(
            locations,
            `provinces.${property.province}.cantons.${property.canton}.districts.${property.district}`,
            ""
          )}`}</p>
        </div>
        <div className="property-price">
          {getCurrencyFormat("es-CR", "CRC", property.price)}
        </div>

        <div>
          <div
            onClick={() => {
              navigator.clipboard.writeText(getRefKey(property.consecutive));
              showToast(
                translator("ToastMessages.success"),
                translator("ToastMessages.consecutiveCopied"),
                "info"
              );
            }}
            className="ref-key-property"
          >
            {`Nº Ref: `}
            {getRefKey(property.consecutive)}
            <FontAwesomeIcon icon={faCopy} />
          </div>
        </div>
      </div>
      <div className="property-info">
        <div className="features-amenities-media">
          {getFiles().length > 0 && (
            <div
              className={`left-section ${
                property?.amenities?.length! === 0 ||
                keys(property.features).length === 0
                  ? "feat-amen-fw"
                  : ""
              }`}
            >
              <div className="media">
                <div className="property-media">
                  {getFiles().length > 0 && (
                    <div className="property-slides">
                      <Carousel
                        interval={0}
                        activeIndex={activeIndex}
                        next={next}
                        previous={previous}
                        fade={true}
                      >
                        {renderSlides(false)}
                        {getFiles().length > 1 && (
                          <>
                            <CarouselIndicators
                              items={renderSlides(false)}
                              activeIndex={activeIndex}
                              onClickHandler={goToIndex}
                            />
                            <div className="carousel-control-prev">
                              <div onClick={previous} className="arrow-button">
                                <FontAwesomeIcon icon={faChevronLeft} />
                              </div>
                            </div>
                            <div className="carousel-control-next">
                              <div onClick={next} className="arrow-button">
                                <FontAwesomeIcon icon={faChevronRight} />
                              </div>
                            </div>
                          </>
                        )}
                      </Carousel>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          {(property?.amenities?.length! > 0 ||
            keys(property.features).length > 0) && (
            <div
              className={`right-section ${
                getFiles().length > 0 ? "padding-rs" : ""
              } ${getFiles().length === 0 ? "feat-amen-fw" : ""}`}
            >
              {keys(property.features).length > 0 && (
                <div className="property-features">
                  <h3 className="title">{translator("Global.features")}</h3>
                  <div className="property-features-list">
                    {keys(property.features).map((featureId) => {
                      const feature = features.find((featureItem) => {
                        return featureItem.id === featureId;
                      });
                      const value =
                        feature?.ref === "SIZE"
                          ? `${
                              baseLanguage === "es"
                                ? feature?.name
                                : feature?.name_en
                            } ${property!.features![featureId]} m2`
                          : `${
                              baseLanguage === "es"
                                ? feature?.name
                                : feature?.name_en
                            } ${property!.features![featureId]}`;

                      return (
                        <div className="feature-item-info">
                          <i className={feature?.icon} />
                          <p>{value}</p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
              {property?.amenities?.length! > 0 && (
                <div className="property-amenities">
                  <h3 className="title">{translator("Global.amenities")}</h3>
                  <div className="property-amenities-list">
                    {property?.amenities!.map((amenitieId) => {
                      const amenitie = amenities.find((amenitieItem) => {
                        return amenitieItem.id === amenitieId;
                      });
                      const value =
                        baseLanguage === "es"
                          ? amenitie?.name
                          : amenitie?.name_en;
                      return (
                        <div className="amenitie-item-info">
                          <FontAwesomeIcon
                            className="amenitie-icon"
                            icon={faCircleCheck}
                          />
                          <p>{value}</p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        {property.description && (
          <div className="property-description">
            <h3 className="title">{translator("Global.description")}</h3>
            <p className="property-info-description">
              {baseLanguage === "es"
                ? property.description
                : property.descriptionEn}
            </p>
          </div>
        )}
        <div className="property-location">
          <h3 className="title">{translator("Global.location")}</h3>

          <div className="location-container">
            <div className="location-left">
              <div className="location-info">
                <div className="location-data">
                  <p className="location-title">{`${translator(
                    "Global.province"
                  )}:`}</p>
                  <p className="location-value">
                    {get(locations, `provinces.${property.province}.name`, "")}
                  </p>
                </div>
                <div className="location-data">
                  <p className="location-title">{`${translator(
                    "Global.canton"
                  )}:`}</p>
                  <p className="location-value">
                    {get(
                      locations,
                      `provinces.${property.province}.cantons.${property.canton}.name`,
                      ""
                    )}
                  </p>
                </div>
                <div className="location-data">
                  <p className="location-title">{`${translator(
                    "Global.district"
                  )}:`}</p>
                  <p className="location-value">
                    {get(
                      locations,
                      `provinces.${property.province}.cantons.${property.canton}.districts.${property.district}`,
                      ""
                    )}
                  </p>
                </div>
                {property.address && property.address.length > 0 && (
                  <div className="location-oths">
                    <p className="location-titlte">
                      {translator("Global.otherSigns")}
                    </p>
                    <p className="location-oths-desc">
                      {baseLanguage === "es"
                        ? property.address
                        : property.addressEn}
                    </p>
                  </div>
                )}
                <p></p>
              </div>
            </div>
            <div className="location-right">
              <div className="map-container">
                <div className="map-property-location">
                  <GoogleMap
                    onClick={() => {
                      if (isMobile) {
                        window.open(
                          `https://www.google.com/maps/search/?api=1&query=${
                            getLocation(property).lat
                          }%2C${getLocation(property).lng}`
                        );
                      }
                    }}
                    zoom={15}
                    center={getLocation(property)}
                    mapContainerClassName="map-container"
                    options={{
                      gestureHandling: "cooperative",
                      fullscreenControl: false,
                      zoomControl: false,
                      mapTypeControl: false,
                      scaleControl: false,
                      streetViewControl: false,
                      styles: mapsStyle,
                      clickableIcons: false,
                    }}
                  >
                    <Marker position={getLocation(property)} />
                  </GoogleMap>
                </div>
              </div>
            </div>
          </div>
        </div>

        {nearbyProperties?.length! > 0 && (
          <div className="nearby-properties">
            <h3 className="title">{translator("Global.nearbyProperties")}</h3>
            <div className="nearby-properties-container">
              {nearbyProperties?.map((pro) => {
                return (
                  <PropertyCard
                    baseLanguage={baseLanguage}
                    property={pro}
                    translator={translator}
                    navigate={navigate}
                    features={features}
                    amenities={amenities}
                    action={() => {}}
                  />
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  ) : null;
}
export default Property;
