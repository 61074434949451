import {
  Img,
  Stack,
  Heading,
  Card,
  CardBody,
  CardFooter,
} from "@chakra-ui/react";
import {
  faCopy,
  faAnglesRight,
  faCircleCheck,
  faEye,
  faLocationDot,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { useAppSelector, useAppDispatch } from "../../../../redux/hooks";
import {
  changeFavoritesProperties,
  addFavoritesProperties,
} from "../../../../redux/reducers/appSettingsSlice";
import { mapsStyle } from "../../../../styles/maps";
import IProperty from "../../../../types/property";
import { getCurrencyFormat } from "../../../../utils/digitsUtils";
import { showToast } from "../../../../utils/toast";
import Button from "../../../layouts/Button";
import "./PropertyCard.scss";
import Modal from "../../../layouts/Modal";
import { useState } from "react";
import { IFeatures } from "../../../../types/features";
import { IAmenities } from "../../../../types/amenities";
import { get, keys } from "lodash";
import locations from "../../../../assets/locations/locations.json";
import { Carousel, CarouselIndicators, CarouselItem } from "reactstrap";
import useIsMobile from "../../../../hooks/useIsMobile";

interface IPropertyCard {
  property: IProperty;
  baseLanguage: any;
  translator: any;
  navigate: any;
  action: Function;
  features: IFeatures[];
  amenities: IAmenities[];
}

function PropertyCard(props: IPropertyCard) {
  const {
    property,
    baseLanguage,
    translator,
    navigate,
    action,
    features,
    amenities,
  } = props;

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const CONSECUTIVE_MAX_LENGTH = 6;
  const favoriteProperties = useAppSelector(
    (state) => state.appSettings.favoritesProperties
  );
  const dispatch = useAppDispatch();
  const isMobile = useIsMobile();

  const getImages = () => {
    if (property.files) {
      const files = property.files.filter((file: any) => {
        let include = false;
        if (file.name.split(".")[1] === "jpg") include = true;
        if (file.name.split(".")[1] === "jpeg") include = true;
        if (file.name.split(".")[1] === "png") include = true;
        return include;
      });
      return files;
    }
    return [];
  };

  const getLocation = () => {
    const values = property.location.split(",");
    const lat = Number(values[0]);
    const lng = Number(values[1]);
    return { lat, lng };
  };

  const getRefKey = (propertyConsecutive) => {
    const consecutiveLength = String(propertyConsecutive).length;
    return `PRO-${"0".repeat(
      CONSECUTIVE_MAX_LENGTH - consecutiveLength
    )}${propertyConsecutive}`;
  };

  const isFavoriteProperty = !!favoriteProperties?.find((fp) => {
    return fp.id === property.id;
  });

  const handleFavoriteProperty = () => {
    if (isFavoriteProperty) {
      dispatch(
        changeFavoritesProperties([
          ...favoriteProperties.filter((fp) => {
            return fp.id !== property.id;
          }),
        ])
      );
    } else {
      dispatch(addFavoritesProperties(property));
    }
  };

  const handleTypeTranslate = (type: string) => {
    switch (type) {
      case "BUILD":
        return translator("Global.construction");
      case "LAND":
        return translator("Global.land");
      case "LAND-BUILD":
        return translator("Global.landConst");
    }
  };

  const renderSlides = (isThumbs) => {
    return getImages().map((file, index) => {
      return (
        <CarouselItem key={index}>
          <Img className={"carousel-image"} src={file.url} />
        </CarouselItem>
      );
    });
  };

  const next = () => {
    const nextIndex =
      activeIndex === getImages().length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    const nextIndex =
      activeIndex === 0 ? getImages().length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    setActiveIndex(newIndex);
  };

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        showCloseButton
        onClose={() => {
          setIsModalOpen(false);
        }}
      >
        <div className="modal-body">
          <div className="media-modal-container">
            {getImages().length > 0 ? (
              <div className="property-media">
                {getImages().length > 0 && (
                  <div className="property-slides">
                    <Carousel
                      interval={0}
                      activeIndex={activeIndex}
                      next={next}
                      previous={previous}
                      fade={true}
                    >
                      {renderSlides(false)}
                      {getImages().length > 1 && (
                        <>
                          <CarouselIndicators
                            items={renderSlides(false)}
                            activeIndex={activeIndex}
                            onClickHandler={goToIndex}
                          />
                          <div className="carousel-control-prev">
                            <div onClick={previous} className="arrow-button">
                              <FontAwesomeIcon icon={faChevronLeft} />
                            </div>
                          </div>
                          <div className="carousel-control-next">
                            <div onClick={next} className="arrow-button">
                              <FontAwesomeIcon icon={faChevronRight} />
                            </div>
                          </div>
                        </>
                      )}
                    </Carousel>
                  </div>
                )}
              </div>
            ) : (
              <div className="map-base-property-card">
                <GoogleMap
                  zoom={15}
                  center={getLocation()}
                  mapContainerClassName="map-container"
                  options={{
                    gestureHandling: "none",
                    fullscreenControl: false,
                    zoomControl: false,
                    mapTypeControl: false,
                    scaleControl: false,
                    streetViewControl: false,
                    rotateControl: false,
                    styles: mapsStyle,
                    clickableIcons: false,
                  }}
                >
                  <Marker position={getLocation()} />
                </GoogleMap>
              </div>
            )}
          </div>
          <div className="data-modal-container">
            <div className="pill-favorite">
              <div className="type-pill">
                {handleTypeTranslate(property!.type)}
              </div>
              {!property.sold && (
                <div
                  onClick={() => {
                    handleFavoriteProperty();
                  }}
                  className="favorite"
                >
                  {isFavoriteProperty ? (
                    <i className="favorite-icon fa-solid fa-heart"></i>
                  ) : (
                    <i className="favorite-icon fa-regular fa-heart"></i>
                  )}
                </div>
              )}
            </div>
            <Heading marginTop={"10px"} size="md">
              {baseLanguage === "es" ? property.name : property.nameEn}
            </Heading>
            <div className="property-location">
              <FontAwesomeIcon icon={faLocationDot} />
              <p>{`${get(
                locations,
                `provinces.${property.province}.name`,
                ""
              )}, ${get(
                locations,
                `provinces.${property.province}.cantons.${property.canton}.name`,
                ""
              )}, ${get(
                locations,
                `provinces.${property.province}.cantons.${property.canton}.districts.${property.district}`,
                ""
              )}`}</p>
            </div>
            <div className="property-price">
              {getCurrencyFormat("es-CR", "CRC", property.price)}
            </div>

            {keys(property.features).length > 0 && (
              <div className="property-features">
                <h3 className="title">{translator("Global.features")}</h3>
                <div className="property-features-list">
                  {keys(property.features).map((featureId) => {
                    const feature = features.find((featureItem) => {
                      return featureItem.id === featureId;
                    });
                    const value =
                      feature?.ref === "SIZE"
                        ? `${
                            baseLanguage === "es"
                              ? feature?.name
                              : feature?.name_en
                          } ${property!.features![featureId]} m2`
                        : `${
                            baseLanguage === "es"
                              ? feature?.name
                              : feature?.name_en
                          } ${property!.features![featureId]}`;

                    return (
                      <div className="feature-item-info">
                        <i className={feature?.icon} />
                        <p>{value}</p>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {property?.amenities!.length > 0 && (
              <div className="property-amenities">
                <h3 className="title">{translator("Global.amenities")}</h3>
                <div className="property-amenities-list">
                  {property?.amenities!.map((amenitieId) => {
                    const amenitie = amenities.find((amenitieItem) => {
                      return amenitieItem.id === amenitieId;
                    });
                    const value =
                      baseLanguage === "es"
                        ? amenitie?.name
                        : amenitie?.name_en;
                    return (
                      <div className="amenitie-item-info">
                        <FontAwesomeIcon
                          className="amenitie-icon"
                          icon={faCircleCheck}
                        />
                        <p>{value}</p>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal>
      <div className="card-component-container">
        <Card
          className="mobile-card"
          direction={{ base: "column", sm: "row" }}
          overflow="hidden"
          variant="outline"
          width={"full"}
        >
          {getImages().length > 0 ? (
            <Img
              objectFit="cover"
              className="property-image"
              src={getImages()[0].url}
            />
          ) : (
            <div className="map-base-property-card">
              <GoogleMap
                zoom={15}
                center={getLocation()}
                mapContainerClassName="map-container"
                options={{
                  gestureHandling: "none",
                  fullscreenControl: false,
                  zoomControl: false,
                  mapTypeControl: false,
                  scaleControl: false,
                  streetViewControl: false,
                  rotateControl: false,
                  styles: mapsStyle,
                  clickableIcons: false,
                }}
              >
                <Marker position={getLocation()} />
              </GoogleMap>
            </div>
          )}

          <Stack
            width={"full"}
            display={"flex"}
            justifyContent={"space-between"}
          >
            <CardBody maxHeight={"150px"}>
              <div className="property-cards-quick-actions">
                <FontAwesomeIcon
                  onClick={() => {
                    setIsModalOpen(true);
                  }}
                  className="preview-icon"
                  icon={faEye}
                />
                {isFavoriteProperty ? (
                  <i
                    onClick={() => {
                      handleFavoriteProperty();
                    }}
                    className="favorite-icon fa-solid fa-heart"
                  ></i>
                ) : (
                  <i
                    onClick={() => {
                      handleFavoriteProperty();
                    }}
                    className="favorite-icon fa-regular fa-heart"
                  ></i>
                )}
              </div>
              <Heading
                maxWidth={"400px"}
                size="md"
                marginTop={isMobile ? "0px" : "10px"}
              >
                {baseLanguage === "es" ? property.name : property.nameEn}
              </Heading>
              <div>
                <div
                  onClick={() => {
                    navigator.clipboard.writeText(
                      getRefKey(property.consecutive)
                    );
                    showToast(
                      translator("ToastMessages.success"),
                      translator("ToastMessages.consecutiveCopied"),
                      "info"
                    );
                  }}
                  className="ref-key"
                >
                  {`Nº Ref: `}
                  {getRefKey(property.consecutive)}
                  <FontAwesomeIcon icon={faCopy} />
                </div>
              </div>
            </CardBody>

            <CardFooter className="card-footer-base">
              <div className="card-footer-container">
                <p className="price-value">
                  {getCurrencyFormat("es-CR", "CRC", property.price)}
                </p>
                {!property.sold && (
                  <Button
                    onClick={() => {
                      action();
                      navigate(`/property/${property.id}`);
                    }}
                    rightIcon={<FontAwesomeIcon icon={faAnglesRight} />}
                  >
                    {translator("Global.viewProperty")}
                  </Button>
                )}
                {property.soldByUs && (
                  <div className="property-status">
                    {translator("Global.sold")}
                  </div>
                )}
              </div>
            </CardFooter>
          </Stack>
        </Card>
      </div>
    </>
  );
}

export default PropertyCard;
