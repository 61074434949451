import { Button, ButtonProps } from "@chakra-ui/react";
import React from "react";
import "./Button.scss";

interface IButtonProps extends ButtonProps {
  ref?: any;
}
function CCButton(props: IButtonProps) {
  const btnProps: IButtonProps = {
    ...props,
    boxShadow: props.boxShadow || "xl",
    colorScheme: props.colorScheme || "primary",
    variant: props.variant || "solid",
  };

  return (
    <Button
      className={`${
        props.colorScheme === "red" ? "color-base-red" : "color-base"
      }`}
      {...btnProps}
    >
      {props.children}
    </Button>
  );
}
export default CCButton;
