import React from "react";

const EnglishFlag = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_8402_100934)">
      <mask
        id="mask0_8402_100934"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <path
          d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_8402_100934)">
        <path
          d="M12 0H24V3L22.5 4.5L24 6V9L22.5 10.5L24 12V15L22.5 16.5L24 18V21L12 22.5L0 21V18L1.5 16.5L0 15V12L12 0Z"
          fill="#EEEEEE"
        />
        <path
          d="M10.5 3H24V6H10.5V3ZM10.5 9H24V12H12L10.5 9ZM0 15H24V18H0V15ZM0 21H24V24H0V21Z"
          fill="#D80027"
        />
        <path d="M0 0H12V12H0V0Z" fill="#0052B4" />
        <path
          d="M8.76562 11.3906L11.4375 9.46875H8.15625L10.8281 11.3906L9.79688 8.25L8.76562 11.3906ZM4.96875 11.3906L7.64062 9.46875H4.35938L7.03125 11.3906L6 8.25L4.96875 11.3906ZM1.17188 11.3906L3.84375 9.46875H0.5625L3.23438 11.3906L2.20312 8.25L1.17188 11.3906ZM8.76562 7.59375L11.4375 5.67188H8.15625L10.8281 7.59375L9.79688 4.45312L8.76562 7.59375ZM4.96875 7.59375L7.64062 5.67188H4.35938L7.03125 7.59375L6 4.45312L4.96875 7.59375ZM1.17188 7.59375L3.84375 5.67188H0.5625L3.23438 7.59375L2.20312 4.45312L1.17188 7.59375ZM8.76562 3.75L11.4375 1.82812H8.15625L10.8281 3.75L9.79688 0.609375L8.76562 3.75ZM4.96875 3.75L7.64062 1.82812H4.35938L7.03125 3.75L6 0.609375L4.96875 3.75ZM1.17188 3.75L3.84375 1.82812H0.5625L3.23438 3.75L2.20312 0.609375L1.17188 3.75Z"
          fill="#EEEEEE"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_8402_100934">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const SpanishFlag = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1_3)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.5 0H24.5V24H0.5V0Z"
        fill="#0000B4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.5 3.77H24.5V19.885H0.5V3.77Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.5 7.885H24.5V15.77H0.5V7.885Z"
        fill="#D90000"
      />
    </g>
    <defs>
      <clipPath id="clip0_1_3">
        <rect x="0.5" width="24" height="24" rx="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const flags = {
  en: EnglishFlag,
  es: SpanishFlag,
};
export default flags;
