/* eslint-disable react-hooks/exhaustive-deps */
import {
  Card,
  CardBody,
  CardFooter,
  Heading,
  Img,
  Stack,
} from "@chakra-ui/react";
import useTranslations from "../../../hooks/useTranslations";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/css/sea-green";
import "./Home.scss";
import Button from "../../layouts/Button";
import { getHomeCarouselImagesPaths } from "../../../services/AppService";
import { IAppImage } from "../../../types/appImage";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getSoldByUsProperties,
  getVisibleProperties,
} from "../../../services/PropertiesService";
import IProperty from "../../../types/property";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { keys, shuffle } from "lodash";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { Row } from "reactstrap";
import { mapsStyle } from "../../../styles/maps";
import { getCurrencyFormat } from "../../../utils/digitsUtils";
import { IFeatures } from "../../../types/features";
import { getFeatures } from "../../../services/FeaturesAmenitiesService";
import {
  faAnglesRight,
  faCopy,
  faMountainSun,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { showToast } from "../../../utils/toast";
import {
  addFavoritesProperties,
  changeFavoritesProperties,
} from "../../../redux/reducers/appSettingsSlice";
import useIsMobile from "../../../hooks/useIsMobile";

interface IPropertyCard {
  property: IProperty;
  baseLanguage: any;
  features: IFeatures[];
  translator: any;
  type?: string;
  navigate: any;
}

function PropertyCard(props: IPropertyCard) {
  const { property, baseLanguage, features, translator, type, navigate } =
    props;
  const CONSECUTIVE_MAX_LENGTH = 6;
  const favoriteProperties = useAppSelector(
    (state) => state.appSettings.favoritesProperties
  );
  const dispatch = useAppDispatch();

  const getImages = () => {
    if (property.files) {
      const files = property.files.filter((file: any) => {
        let include = false;
        if (file.name.split(".")[1] === "jpg") include = true;
        if (file.name.split(".")[1] === "jpeg") include = true;
        if (file.name.split(".")[1] === "png") include = true;
        return include;
      });
      return files;
    }
    return [];
  };

  const getLocation = () => {
    const values = property.location.split(",");
    const lat = Number(values[0]);
    const lng = Number(values[1]);
    return { lat, lng };
  };

  const getRefKey = (propertyConsecutive) => {
    const consecutiveLength = String(propertyConsecutive).length;
    return `PRO-${"0".repeat(
      CONSECUTIVE_MAX_LENGTH - consecutiveLength
    )}${propertyConsecutive}`;
  };

  const isFavoriteProperty = !!favoriteProperties?.find((fp) => {
    return fp.id === property.id;
  });

  const handleFavoriteProperty = () => {
    if (isFavoriteProperty) {
      dispatch(
        changeFavoritesProperties([
          ...favoriteProperties.filter((fp) => {
            return fp.id !== property.id;
          }),
        ])
      );
    } else {
      dispatch(addFavoritesProperties(property));
    }
  };

  const buildFeaturesLabel = (features: string[]) => {
    let label = "";
    features.forEach((f) => {
      label = label + f;
    });
    return label;
  };

  return (
    <div className="card-container">
      {type !== "lg" && (
        <Card width={"full"}>
          <CardBody className="card-body" padding={0}>
            <div className="add-favorite">
              {isFavoriteProperty ? (
                <i
                  onClick={() => {
                    handleFavoriteProperty();
                  }}
                  className="favorite-icon fa-solid fa-heart"
                ></i>
              ) : (
                <i
                  onClick={() => {
                    handleFavoriteProperty();
                  }}
                  className="favorite-icon fa-regular fa-heart"
                ></i>
              )}
            </div>
            {getImages().length > 0 ? (
              <Img
                className="property-image"
                src={getImages()[0].url}
                borderRadius="lg"
              />
            ) : (
              <div className="map-base-property-card">
                <GoogleMap
                  zoom={15}
                  center={getLocation()}
                  mapContainerClassName="map-container"
                  options={{
                    gestureHandling: "none",
                    fullscreenControl: false,
                    zoomControl: false,
                    mapTypeControl: false,
                    scaleControl: false,
                    streetViewControl: false,
                    rotateControl: false,
                    styles: mapsStyle,
                    clickableIcons: false,
                  }}
                >
                  <Marker position={getLocation()} />
                </GoogleMap>
              </div>
            )}
            <Stack mt="6" padding={"10px"} spacing="3">
              <Heading margin={0} size="md">
                {baseLanguage === "es" ? property.name : property.nameEn}
              </Heading>
              <div
                onClick={() => {
                  navigator.clipboard.writeText(
                    getRefKey(property.consecutive)
                  );
                  showToast(
                    translator("ToastMessages.success"),
                    translator("ToastMessages.consecutiveCopied"),
                    "info"
                  );
                }}
                className="ref-key"
              >
                {`Nº Ref: `}
                {getRefKey(property.consecutive)}
                <FontAwesomeIcon icon={faCopy} />
              </div>
              <Row>
                <div>{`${getCurrencyFormat(
                  "es-CR",
                  "CRC",
                  property.price
                )}`}</div>
              </Row>
              {property.features && (
                <div className="features-list">
                  {keys(property.features).map((featureId) => {
                    const feature = features.find((featureItem) => {
                      return featureItem.id === featureId;
                    });
                    const value =
                      feature?.ref === "SIZE"
                        ? `${
                            baseLanguage === "es"
                              ? feature?.name
                              : feature?.name_en
                          } ${property!.features![featureId]} m2`
                        : `${
                            baseLanguage === "es"
                              ? feature?.name
                              : feature?.name_en
                          } ${property!.features![featureId]}`;

                    return <p className="feature-item-info">{value}</p>;
                  })}
                </div>
              )}
            </Stack>
          </CardBody>
          <CardFooter>
            <div className="interes-property-btn-container">
              <Button
                onClick={() => {
                  navigate(`/property/${property.id}`);
                }}
                rightIcon={<FontAwesomeIcon icon={faAnglesRight} />}
                width={"full"}
              >
                {translator("Global.viewProperty")}
              </Button>
            </div>
          </CardFooter>
        </Card>
      )}
      {type === "lg" && (
        <Card
          direction={{ base: "column", sm: "row" }}
          overflow="hidden"
          variant="outline"
          width={"full"}
        >
          {getImages().length > 0 ? (
            <Img
              objectFit="cover"
              className="property-image"
              // maxW={{ base: "100%", sm: "200px" }}
              src={getImages()[0].url}
            />
          ) : (
            <div className="map-base-property-card">
              <GoogleMap
                zoom={15}
                center={getLocation()}
                mapContainerClassName="map-container"
                options={{
                  gestureHandling: "none",
                  fullscreenControl: false,
                  zoomControl: false,
                  mapTypeControl: false,
                  scaleControl: false,
                  streetViewControl: false,
                  rotateControl: false,
                  styles: mapsStyle,
                  clickableIcons: false,
                }}
              >
                <Marker position={getLocation()} />
              </GoogleMap>
            </div>
          )}

          <Stack>
            <CardBody>
              <Heading size="md">
                {baseLanguage === "es" ? property.name : property.nameEn}
              </Heading>

              <p>{getCurrencyFormat("es-CR", "CRC", property.price)}</p>
              {keys(property.features).length > 0 && (
                <p className="features-as-text">
                  {buildFeaturesLabel([
                    ...keys(property.features).map((featureId, index) => {
                      const feature = features.find((featureItem) => {
                        return featureItem.id === featureId;
                      });
                      const value =
                        feature?.ref === "SIZE"
                          ? `${
                              baseLanguage === "es"
                                ? feature?.name
                                : feature?.name_en
                            } ${property!.features![featureId]} m2`
                          : `${
                              baseLanguage === "es"
                                ? feature?.name
                                : feature?.name_en
                            } ${property!.features![featureId]}`;
                      if (index > 0) {
                        return ` - ${value}`;
                      }
                      return value;
                    }),
                  ])}
                </p>
              )}
            </CardBody>

            <CardFooter>
              <Button
                onClick={() => {
                  navigate(`/property/${property.id}`);
                }}
                rightIcon={<FontAwesomeIcon icon={faAnglesRight} />}
              >
                {translator("Global.viewProperty")}
              </Button>
            </CardFooter>
          </Stack>
        </Card>
      )}
    </div>
  );
}

interface IHomeProps {}

function Home(props: IHomeProps) {
  const [carouselImages, setCarouselImages] = useState<IAppImage[]>([]);
  const [propertiesList, setPropertiesList] = useState<IProperty[]>([]);
  const [ourSalespropertiesList, setOurSalesPropertiesList] = useState<
    IProperty[]
  >([]);
  const [features, setFeatures] = useState<IFeatures[]>([]);

  const translator = useTranslations();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const baseLanguage = useAppSelector((state) => state.appSettings.language);

  const getWelcomeCarouselImages = async () => {
    const data: IAppImage[] = await getHomeCarouselImagesPaths(translator);
    setCarouselImages(data);
  };

  const getPropertiesData = async () => {
    const data: IProperty[] = await getVisibleProperties()!;
    const soldByUsPropertiesData: IProperty[] = await getSoldByUsProperties()!;
    const auxProperties = shuffle(data);
    const auxPropertiesSoldByUs = shuffle(soldByUsPropertiesData);
    if (auxProperties.length >= 3) {
      setPropertiesList([auxProperties[0], auxProperties[1], auxProperties[2]]);
    } else {
      setPropertiesList(auxProperties);
    }

    if (auxPropertiesSoldByUs.length >= 3) {
      setOurSalesPropertiesList([
        auxPropertiesSoldByUs[0],
        auxPropertiesSoldByUs[1],
        auxPropertiesSoldByUs[2],
      ]);
    } else {
      setOurSalesPropertiesList(auxPropertiesSoldByUs);
    }
  };
  const getFeaturesData = async () => {
    const data: IFeatures[] = await getFeatures(translator);
    setFeatures(data);
  };

  useEffect(() => {
    setCarouselImages([]);
    getWelcomeCarouselImages();
    getPropertiesData();
    getFeaturesData();
  }, []);

  return (
    <div className="home-container">
      <div className="welcome-container">
        <div className="welcome-info">
          <h2 className="title">{translator("Home.welcomeTitle")}</h2>
          <p className="description">{translator("Home.welcomeDescription")}</p>
          <Button
            width={isMobile ? "full" : "auto"}
            rightIcon={<FontAwesomeIcon icon={faMountainSun} />}
            onClick={() => {
              navigate("/properties");
            }}
          >
            {translator("Home.viewProperties")}
          </Button>
        </div>
        <div className="welcome-images">
          {carouselImages.length > 0 && (
            <Splide
              options={{
                type: "fade",
                speed: 3000,
                interval: 6000,
                arrows: false,
                autoplay: true,
                rewind: true,
                rewindByDrag: false,
                pauseOnFocus: false,
                pauseOnHover: false,
                pagination: false,
                drag: false,
                width: "600px",
                height: "360px",
                padding: "0px",
              }}
            >
              {carouselImages.map((imPath) => {
                return (
                  <SplideSlide>
                    <Img className="carousel-image" src={imPath.url} />
                  </SplideSlide>
                );
              })}
            </Splide>
          )}
        </div>
      </div>
      <div className="interesting-properties">
        <p className="title">{translator("Home.interestingProperties")}</p>
        <p className="description">
          {translator("Home.interestingPropertiesDesc")}
        </p>
        <div className="some-properties">
          {propertiesList.map((property) => {
            return (
              <PropertyCard
                property={property}
                baseLanguage={baseLanguage}
                features={features}
                translator={translator}
                navigate={navigate}
              />
            );
          })}
        </div>
      </div>
      {ourSalespropertiesList.length > 0 && (
        <div className="our-sales">
          <p className="title">{translator("Home.soldProperties")}</p>
          <p className="description">{translator("Home.soldPropertiesDesc")}</p>
          <div className="some-properties">
            {ourSalespropertiesList.map((property) => {
              return (
                <PropertyCard
                  property={property}
                  baseLanguage={baseLanguage}
                  features={features}
                  translator={translator}
                  type="lg"
                  navigate={navigate}
                />
              );
            })}
          </div>
        </div>
      )}
      <div className="contact-us">
        <p className="title">{translator("Global.contactUs")}</p>
        <p className="description">{translator("Global.contactDesc")}</p>
        <div className="contact-button">
          <Button
            width={isMobile ? "full" : "auto"}
            onClick={() => {
              window.scrollTo({ left: 0, top: 0 });
              navigate("/contact");
            }}
            rightIcon={<FontAwesomeIcon icon={faPaperPlane} />}
          >
            {translator("Global.contactUs")}
          </Button>
        </div>
      </div>
    </div>
  );
}
export default Home;
