/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Img,
  Stack,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  Switch,
  useDisclosure,
  useSteps,
} from "@chakra-ui/react";
import Modal from "../../../layouts/Modal";
import "./NewProperty.scss";
import Input from "../../../layouts/Input";
import { Formik } from "formik";
import Select from "react-select";

import { GoogleMap, Marker } from "@react-google-maps/api";
import locations from "../../../../assets/locations/locations.json";

import { useEffect, useState } from "react";
import Button from "../../../layouts/Button";
import {
  getAmenities,
  getFeatures,
} from "../../../../services/FeaturesAmenitiesService";
import { IAmenities } from "../../../../types/amenities";
import { IFeatures } from "../../../../types/features";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import AddFeatures from "./AddFeature";
import AttachmentUpload from "../../../layouts/AttachmentUpload";
import { get, keys } from "lodash";
import { Col, Row } from "reactstrap";
import * as yup from "yup";
import IProperty from "../../../../types/property";
import {
  addProperty,
  deletePropertyAttachments,
  getPropertyAttachments,
  savePropertyAttachments,
  updateProperty,
} from "../../../../services/PropertiesService";
import { Navigate, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faColonSign } from "@fortawesome/free-solid-svg-icons";
import { changeIsLoading } from "../../../../redux/reducers/appSettingsSlice";
import { mapsStyle } from "../../../../styles/maps";
import { isJWTValid } from "../../../../utils/jwtUtils";

interface INewProperty {
  translator: any;
  isOpen: boolean;
  setIsOpen: any;
  selectedProperty: IProperty | null;
  setSelectedProperty: Function;
  getPropertiesData: Function;
}

function NewProperty(props: INewProperty) {
  const {
    translator,
    isOpen,
    setIsOpen,
    selectedProperty,
    setSelectedProperty,
    getPropertiesData,
  } = props;
  const { onClose } = useDisclosure();

  const [amenities, setAmenities] = useState<IAmenities[]>([]);
  const [features, setFeatures] = useState<IFeatures[]>([]);
  const userToken = useAppSelector((state) => state.session.token)!;
  const navigate = useNavigate();
  const [center, setCenter] = useState({
    lat: 9.365938850894745,
    lng: -83.69688559182259,
  });
  const [selectedLocation, setSelectedLocation] = useState<any>(null);

  const [currentgAttachmentsList, setCurrentgAttachments] = useState<any>([]);
  const [newAttachmentsList, setNewAttachments] = useState<any>([]);
  const [deletedAttchmentsList, setDeletedAttchments] = useState<any>([]);
  const [selectedFeaturesErrors, setSelectFeaturesErrors] = useState<any>([]);
  const dispatch = useAppDispatch();

  const toogleIsLoading = (isLoading) => {
    dispatch(changeIsLoading(isLoading));
  };
  const baseLanguage = useAppSelector((state) => state.appSettings.language);
  const isLoaded = useAppSelector((state) => state.appSettings.isMapLoaded);
  const appUser = useAppSelector((state) => state.session.user);
  const token = useAppSelector((state) => state.session.token);

  const provinces = get(locations, "provinces");

  const onCloseModal = () => {
    setIsOpen(false);
    onClose();
  };

  const steps = [
    {
      title: translator("Global.first"),
      description: translator("Global.generalInformation"),
    },
    {
      title: translator("Global.second"),
      description: translator("NewProperty.featuresAndAmenities"),
    },
    {
      title: translator("Global.third"),
      description: translator("NewProperty.images"),
    },
  ];

  const { activeStep, goToNext, goToPrevious, setActiveStep } = useSteps({
    index: 1,
    count: steps.length,
  });

  const handleTypeTranslate = (type: string) => {
    switch (type) {
      case "BUILD":
        return translator("Global.construction");
      case "LAND":
        return translator("Global.land");
      case "LAND-BUILD":
        return translator("Global.landConst");
    }
  };

  const initialValues = {
    soldByUs: selectedProperty ? selectedProperty.soldByUs : false,
    sold: selectedProperty ? selectedProperty.sold : false,
    visible: selectedProperty ? selectedProperty.visible : false,
    price: selectedProperty ? selectedProperty.price : "",
    name: selectedProperty ? selectedProperty.name : "",
    nameEn: selectedProperty ? selectedProperty.nameEn : "",
    description: selectedProperty ? selectedProperty.description : "",
    descriptionEn: selectedProperty ? selectedProperty.descriptionEn : "",
    type: selectedProperty
      ? {
          label: handleTypeTranslate(selectedProperty.type),
          value: selectedProperty.type,
        }
      : null,
    selectedFeatures: selectedProperty ? selectedProperty.features : {},
    selectedAmenities: selectedProperty
      ? selectedProperty.amenities?.map((am) => {
          const amAux = amenities.find((ami) => {
            return ami.id === am;
          });
          return {
            label: baseLanguage === "es" ? amAux?.name : amAux?.name_en,
            value: am,
          };
        })
      : [],
    province: selectedProperty
      ? {
          label: get(
            locations,
            `provinces.${selectedProperty.province}.name`,
            ""
          ),
          value: selectedProperty.province,
        }
      : null,
    canton: selectedProperty
      ? {
          label: get(
            locations,
            `provinces.${selectedProperty.province}.cantons.${selectedProperty.canton}.name`,
            ""
          ),
          value: selectedProperty.canton,
        }
      : null,
    district: selectedProperty
      ? {
          label: get(
            locations,
            `provinces.${selectedProperty.province}.cantons.${selectedProperty.canton}.districts.${selectedProperty.district}`,
            ""
          ),
          value: selectedProperty.district,
        }
      : null,
    address: selectedProperty ? selectedProperty.address : "",
    addressEn: selectedProperty ? selectedProperty.addressEn : "",
    location: selectedProperty ? selectedProperty.location : "",
    seller: selectedProperty ? selectedProperty.seller : "",
    sellerPhone: selectedProperty ? selectedProperty.sellerPhone : "",
    sellerEmail: selectedProperty ? selectedProperty.sellerEmail : "",
  };

  const getPropertyFiles = async (property: IProperty) => {
    if (property.files) {
      if (property.files.length > 0) {
        const files: File[] | null = await getPropertyAttachments(
          property?.id!,
          property.files.map((file: File) => {
            return file.name;
          })
        );
        files.length > 0 &&
          handleList(
            [...currentgAttachmentsList, ...files],
            newAttachmentsList,
            deletedAttchmentsList
          );
      }
    }
  };

  const validationSchema = yup.object().shape({
    price: yup
      .number()
      .typeError(translator("Global.invalidValue"))
      .required(translator("Global.requieredField")),
    name: yup.string().required(translator("Global.requieredField")),
    nameEn: yup.string().required(translator("Global.requieredField")),

    type: yup
      .object()
      .shape({
        value: yup.string().required(translator("Global.requieredField")),
      })
      .required(translator("Global.requieredField")),
    province: yup
      .object()
      .shape({})
      .required(translator("Global.requieredField")),
    canton: yup
      .object()
      .shape({})
      .required(translator("Global.requieredField")),
    district: yup
      .object()
      .shape({})
      .required(translator("Global.requieredField")),

    location: yup
      .string()
      .trim()
      .matches(
        /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/,
        translator("Global.invalidLocation")
      )
      .required(translator("Global.requieredField")),
    seller: yup.string().required(translator("Global.requieredField")),
    sellerPhone: yup
      .number()
      .typeError(translator("Global.invalidPhone"))
      .min(20000000, translator("Global.invalidPhone"))
      .max(89999999, translator("Global.invalidPhone"))
      .required(translator("Global.requieredField")),

    sellerEmail: yup.string().email(translator("Global.invalidEmail")),
  });

  const getAmenitiesData = async () => {
    const data: IAmenities[] = await getAmenities(translator);
    setAmenities(data);
  };
  const getFeaturesData = async () => {
    const data: IFeatures[] = await getFeatures(translator);
    setFeatures(data);
  };

  const handleList = (
    currentAttachments,
    newAttachments,
    deletedAttachments
  ) => {
    setCurrentgAttachments(currentAttachments);
    setNewAttachments(newAttachments);
    setDeletedAttchments(deletedAttachments);
  };

  useEffect(() => {
    setAmenities([]);
    setFeatures([]);
    getAmenitiesData();
    getFeaturesData();
  }, []);

  useEffect(() => {
    const values = selectedProperty?.location.split(",");
    const lat = Number(values ? values[0] : null);
    const lng = Number(values ? values[1] : null);
    lat && lng ? setSelectedLocation({ lat, lng }) : setSelectedLocation(null);
    lat && lng && setCenter({ lat, lng });
    selectedProperty && getPropertyFiles(selectedProperty);
    selectedProperty && setCenter({ lat, lng });
    selectedProperty && setSelectedLocation({ lat, lng });
  }, [selectedProperty]);

  const buildProperty = (values, isNew, files: []) => {
    const auxProperty: IProperty = {
      id: !isNew ? selectedProperty?.id : undefined,
      name: values.name,
      nameEn: values.nameEn,
      description: values.description,
      descriptionEn: values.descriptionEn,
      type: values.type.value,
      features: values.selectedFeatures,
      amenities: values.selectedAmenities.map((am) => {
        return am.value;
      }),
      province: values.province.value,
      canton: values.canton.value,
      district: values.district.value,
      address: values.address,
      addressEn: values.addressEn,
      location: values.location,
      seller: values.seller,
      sellerPhone: values.sellerPhone,
      sellerEmail: values.sellerEmail,
      files: !isNew ? selectedProperty?.files : undefined,
      price: values.price,
      visible: values.visible,
      status: "active",
      sold: values.sold,
      soldByUs: values.soldByUs,
      consecutive: !isNew ? selectedProperty?.consecutive : -2,
    };

    return auxProperty;
  };

  const handleCloseModal = (values, resetForm, validateForm) => {
    resetForm();
    setSelectFeaturesErrors([]);
    handleList([], [], []);
    setCenter({
      lat: 9.365938850894745,
      lng: -83.69688559182259,
    });
    setSelectedLocation(null);
    validateForm(values);
    setSelectedProperty(null);
    setActiveStep(1);
    onCloseModal();
  };

  return (
    <>
      {appUser && isJWTValid(token) ? (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnBlur
          validateOnChange
          validateOnMount
          onSubmit={async (values, { resetForm, validateForm }) => {
            const isNew = !selectedProperty;
            const property = buildProperty(values, isNew, []);
            let status = true;
            toogleIsLoading(true);
            if (isNew) {
              await addProperty(
                property,
                translator,
                true,
                userToken,
                navigate
              ).then(async (propertyWithId) => {
                status = !!propertyWithId;
                if (propertyWithId) {
                  let needUpdate = false;
                  if (deletedAttchmentsList.length > 0) {
                    needUpdate = true;
                    await deletePropertyAttachments(
                      propertyWithId.id,
                      deletedAttchmentsList
                    );
                  }
                  if (newAttachmentsList.length > 0) {
                    needUpdate = true;
                    const filesURLs = await savePropertyAttachments(
                      propertyWithId?.id!,
                      newAttachmentsList
                    );
                    propertyWithId.files = filesURLs.map((file) => {
                      return { name: file.name, url: file.url };
                    });
                  }
                  needUpdate &&
                    (await updateProperty(
                      propertyWithId,
                      translator,
                      false,
                      userToken,
                      navigate
                    ));
                }
              });
            } else {
              if (deletedAttchmentsList.length > 0) {
                await deletePropertyAttachments(
                  property.id,
                  deletedAttchmentsList
                );
                if (property.files) {
                  const deleteNames = deletedAttchmentsList.map((df) => {
                    return df.name;
                  });
                  property.files = [
                    ...property.files.filter((value: any) => {
                      return !deleteNames.includes(value.name);
                    }),
                  ];
                }
              }
              if (newAttachmentsList.length > 0) {
                const filesURLs = await savePropertyAttachments(
                  property.id!,
                  newAttachmentsList
                );
                if (property.files) {
                  property.files = [...property.files, ...filesURLs];
                } else {
                  property.files = [...filesURLs];
                }
              }
              status = await updateProperty(
                property,
                translator,
                true,
                userToken,
                navigate
              );
            }
            toogleIsLoading(false);
            if (status) {
              await getPropertiesData();
              handleCloseModal(values, resetForm, validateForm);
            }
          }}
        >
          {({
            values,
            submitForm,
            setFieldValue,
            errors,
            resetForm,
            validateForm,
          }) => {
            const disableNextButton =
              activeStep === 1
                ? keys(errors).length > 0
                : activeStep === 2
                ? keys(selectedFeaturesErrors).length > 0
                : false;
            return (
              <Modal
                id="add-property-modal"
                maxW="1000px"
                minH="600px"
                modalHeader={
                  !selectedProperty
                    ? translator("AdmProperties.newProperty")
                    : translator("AdmProperties.editProperty")
                }
                showCloseButton
                isOpen={isOpen}
                onClose={() => {
                  handleCloseModal(values, resetForm, validateForm);
                }}
                isCentered
                footer={
                  <div className="buttons-container">
                    {activeStep !== 1 && (
                      <Button onClick={goToPrevious}>
                        {translator("Global.goBack")}
                      </Button>
                    )}
                    {activeStep !== 3 && (
                      <Button isDisabled={disableNextButton} onClick={goToNext}>
                        {translator("Global.next")}
                      </Button>
                    )}
                    {activeStep === 3 && (
                      <Button onClick={submitForm}>
                        {translator("Global.save")}
                      </Button>
                    )}
                  </div>
                }
              >
                <div className="newPropertyModalContainer">
                  <Stepper size="lg" index={activeStep}>
                    {steps.map((step, index) => (
                      <Step key={index}>
                        <StepIndicator className="stepper-indicator">
                          <StepStatus
                            complete={<StepIcon />}
                            incomplete={<StepNumber />}
                            active={<StepNumber />}
                          />
                        </StepIndicator>

                        <Box flexShrink="0">
                          <StepTitle>{step.title}</StepTitle>
                          <StepDescription>{step.description}</StepDescription>
                        </Box>

                        <StepSeparator className="stepper-separator" />
                      </Step>
                    ))}
                  </Stepper>
                  <div className="stepper-body">
                    {activeStep === 1 && (
                      <div>
                        <Stack spacing={"15px"}>
                          <h4 className={"section-title"}>
                            {translator("NewProperty.propertyInfo")}
                          </h4>
                          <div>
                            <FormControl display="flex" alignItems="center">
                              <FormLabel htmlFor="status" fontWeight={"600"}>
                                {translator("NewProperty.makeVisible")}
                              </FormLabel>
                              <Switch
                                onChange={(e) => {
                                  setFieldValue(
                                    "visible",
                                    e.currentTarget.checked
                                  );
                                }}
                                isChecked={values.visible}
                                id="status"
                              />
                            </FormControl>
                          </div>
                          <Row>
                            <Col className="col-center" xl={"auto"}>
                              <FormControl display="flex" alignItems="center">
                                <FormLabel htmlFor="sold" fontWeight={"600"}>
                                  {translator("NewProperty.markAsSold")}
                                </FormLabel>
                                <Switch
                                  onChange={(e) => {
                                    setFieldValue(
                                      "sold",
                                      e.currentTarget.checked
                                    );
                                    if (!e.currentTarget.checked) {
                                      setFieldValue("soldByUs", false);
                                    }
                                  }}
                                  isChecked={values.sold}
                                  id="sold"
                                />
                              </FormControl>
                            </Col>
                            <Col xl={"auto"}>
                              {values.sold && (
                                <Img
                                  className={
                                    !values.soldByUs ? "sc_disabled" : ""
                                  }
                                  boxSize="50px"
                                  minWidth={"50px"}
                                  onClick={() => {
                                    setFieldValue("soldByUs", !values.soldByUs);
                                  }}
                                  src={require("../../../../assets/images/sc.png")}
                                />
                              )}
                            </Col>
                          </Row>
                          <Box>
                            <Input
                              translator={translator}
                              leftChild={<FontAwesomeIcon icon={faColonSign} />}
                              label={translator("Global.price")}
                              value={values.price}
                              validationSchema={validationSchema}
                              errors={errors}
                              name="price"
                              onChange={(e) => {
                                setFieldValue("price", e.currentTarget.value);
                              }}
                            />
                          </Box>
                          <Box>
                            <Input
                              translator={translator}
                              label={translator("Global.name")}
                              value={values.name}
                              validationSchema={validationSchema}
                              errors={errors}
                              name="name"
                              onChange={(e) => {
                                setFieldValue("name", e.currentTarget.value);
                              }}
                            />
                          </Box>
                          <Box>
                            <Input
                              translator={translator}
                              label={translator("Global.nameEn")}
                              value={values.nameEn}
                              validationSchema={validationSchema}
                              errors={errors}
                              name="nameEn"
                              onChange={(e) => {
                                setFieldValue("nameEn", e.currentTarget.value);
                              }}
                            />
                          </Box>
                          <Box>
                            <Input
                              translator={translator}
                              label={translator("Global.description")}
                              type="textArea"
                              value={values.description}
                              onChange={(e) => {
                                setFieldValue(
                                  "description",
                                  e.currentTarget.value
                                );
                              }}
                            />
                          </Box>
                          <Box>
                            <Input
                              translator={translator}
                              label={translator("Global.descriptionEn")}
                              type="textArea"
                              value={values.descriptionEn}
                              onChange={(e) => {
                                setFieldValue(
                                  "descriptionEn",
                                  e.currentTarget.value
                                );
                              }}
                            />
                          </Box>
                          <Box>
                            <FormControl
                              isRequired
                              isInvalid={!!errors["type"]}
                            >
                              <FormLabel fontWeight={"600"}>
                                {translator("Global.type")}
                              </FormLabel>
                              <Select
                                classNamePrefix={
                                  errors["type"]
                                    ? "new-select-error"
                                    : "new-select"
                                }
                                value={values.type}
                                options={[
                                  {
                                    value: "LAND",
                                    label: translator("Global.land"),
                                  },
                                  {
                                    value: "BUILD",
                                    label: translator("Global.construction"),
                                  },
                                  {
                                    value: "LAND-BUILD",
                                    label: translator("Global.landConst"),
                                  },
                                ]}
                                onChange={(option) => {
                                  setFieldValue("type", option);
                                }}
                              />
                              <FormErrorMessage>
                                {errors["type"]}
                              </FormErrorMessage>
                            </FormControl>
                          </Box>

                          <Row>
                            <Col xl={"6"}>
                              <FormControl
                                isRequired
                                isInvalid={!!errors["province"]}
                              >
                                <FormLabel fontWeight={"600"}>
                                  {translator("Global.province")}
                                </FormLabel>
                                <Select
                                  value={values.province}
                                  classNamePrefix={
                                    errors["province"]
                                      ? "new-select-error"
                                      : "new-select"
                                  }
                                  onChange={(e) => {
                                    setFieldValue("canton", null);
                                    setFieldValue("district", null);
                                    setFieldValue("province", e);
                                  }}
                                  options={[
                                    ...keys(provinces).map((p) => {
                                      const province = provinces[p];
                                      return { value: p, label: province.name };
                                    }),
                                  ]}
                                />
                                <FormErrorMessage>
                                  {errors["province"]}
                                </FormErrorMessage>
                              </FormControl>
                            </Col>
                            <Col xl={"6"}>
                              <FormControl
                                isRequired
                                isInvalid={!!errors["canton"]}
                              >
                                <FormLabel fontWeight={"600"}>
                                  {translator("Global.canton")}
                                </FormLabel>
                                <Select
                                  classNamePrefix={
                                    errors["canton"]
                                      ? "new-select-error"
                                      : "new-select"
                                  }
                                  value={values.canton}
                                  onChange={(e) => {
                                    setFieldValue("district", null);
                                    setFieldValue("canton", e);
                                  }}
                                  options={
                                    get(values.province, "value") !== ""
                                      ? [
                                          ...keys(
                                            get(
                                              locations,
                                              `provinces.${get(
                                                values.province,
                                                "value"
                                              )}.cantons`
                                            )
                                          ).map((c) => {
                                            const canton: any = get(
                                              locations,
                                              `provinces.${get(
                                                values.province,
                                                "value"
                                              )}.cantons`
                                            )![c];
                                            return {
                                              value: c,
                                              label: canton.name,
                                            };
                                          }),
                                        ]
                                      : []
                                  }
                                />
                                <FormErrorMessage>
                                  {errors["canton"]}
                                </FormErrorMessage>
                              </FormControl>
                            </Col>
                          </Row>
                          <Row>
                            <Col xl={"6"}>
                              <FormControl
                                isRequired
                                isInvalid={!!errors["district"]}
                              >
                                <FormLabel fontWeight={"600"}>
                                  {translator("Global.district")}
                                </FormLabel>
                                <Select
                                  classNamePrefix={
                                    errors["district"]
                                      ? "new-select-error"
                                      : "new-select"
                                  }
                                  value={values.district}
                                  onChange={(e) => {
                                    setFieldValue("district", e);
                                  }}
                                  options={
                                    get(values.canton, "value") !== ""
                                      ? [
                                          ...keys(
                                            get(
                                              locations,
                                              `provinces.${get(
                                                values.province,
                                                "value"
                                              )}.cantons.${get(
                                                values.canton,
                                                "value"
                                              )}.districts`
                                            )
                                          ).map((c) => {
                                            const district: any = get(
                                              locations,
                                              `provinces.${get(
                                                values.province,
                                                "value"
                                              )}.cantons.${get(
                                                values.canton,
                                                "value"
                                              )}.districts`
                                            )![c];
                                            return {
                                              value: c,
                                              label: district,
                                            };
                                          }),
                                        ]
                                      : []
                                  }
                                />
                                <FormErrorMessage>
                                  {errors["district"]}
                                </FormErrorMessage>
                              </FormControl>
                            </Col>
                            <Col>
                              <Input
                                value={values.location}
                                validationSchema={validationSchema}
                                translator={translator}
                                label={translator("Global.location")}
                                name="location"
                                errors={errors}
                                onChange={(e) => {
                                  const values =
                                    e.currentTarget.value.split(",");
                                  const lat = Number(values[0]);
                                  const lng = Number(values[1]);
                                  setFieldValue(
                                    "location",
                                    e.currentTarget.value
                                  );
                                  lat && lng
                                    ? setSelectedLocation({ lat, lng })
                                    : setSelectedLocation(null);
                                  lat && lng && setCenter({ lat, lng });
                                  // setSelected({ lat, lng });
                                }}
                              />
                            </Col>
                          </Row>
                          <Box>
                            <Input
                              translator={translator}
                              type="textArea"
                              label={translator("Global.otherSigns")}
                              value={values.address}
                              onChange={(e) => {
                                setFieldValue("address", e.currentTarget.value);
                              }}
                            />
                          </Box>
                          <Box>
                            <Input
                              translator={translator}
                              type="textArea"
                              label={translator("Global.otherSignsEn")}
                              value={values.addressEn}
                              onChange={(e) => {
                                setFieldValue(
                                  "addressEn",
                                  e.currentTarget.value
                                );
                              }}
                            />
                          </Box>

                          {isLoaded ? (
                            <Box>
                              <Map
                                center={center}
                                selectedLocation={selectedLocation}
                              />
                            </Box>
                          ) : (
                            <div>Loading...</div>
                          )}
                          <h4 className={"section-title"}>
                            {translator("NewProperty.sellerInfo")}
                          </h4>
                          <Box>
                            <Input
                              translator={translator}
                              label={translator("Global.name")}
                              value={values.seller}
                              validationSchema={validationSchema}
                              errors={errors}
                              name="seller"
                              onChange={(e) => {
                                setFieldValue("seller", e.currentTarget.value);
                              }}
                            />
                          </Box>
                          <Row className="seller-contact-info">
                            <Col xl={"6"}>
                              <Input
                                translator={translator}
                                label={translator("Global.email")}
                                value={values.sellerEmail}
                                validationSchema={validationSchema}
                                errors={errors}
                                name="sellerEmail"
                                omitRequired
                                onChange={(e) => {
                                  setFieldValue(
                                    "sellerEmail",
                                    e.currentTarget.value
                                  );
                                }}
                              />
                            </Col>
                            <Col xl={"6"}>
                              <Input
                                translator={translator}
                                label={translator("Global.phoneNumber")}
                                value={values.sellerPhone}
                                validationSchema={validationSchema}
                                errors={errors}
                                name="sellerPhone"
                                onChange={(e) => {
                                  setFieldValue(
                                    "sellerPhone",
                                    e.currentTarget.value
                                  );
                                }}
                              />
                            </Col>
                          </Row>
                        </Stack>
                      </div>
                    )}

                    {activeStep === 2 && (
                      <div className="amenities-features-container">
                        <div className="amenities">
                          <FormLabel fontWeight={"600"}>
                            {translator("Global.amenities")}
                          </FormLabel>
                          <Select
                            classNamePrefix={"new-select"}
                            isMulti
                            value={values.selectedAmenities}
                            options={amenities.map((a, i) => {
                              return {
                                label:
                                  baseLanguage === "es" ? a.name : a.name_en,
                                value: a.id,
                              } as any;
                            })}
                            onChange={(options) => {
                              setFieldValue("selectedAmenities", options);
                            }}
                          />
                        </div>
                        <AddFeatures
                          errors_fmk={selectedFeaturesErrors}
                          setErrors={setSelectFeaturesErrors}
                          translator={translator}
                          features={features}
                          baseLang={baseLanguage}
                          values={values}
                          setFieldValue={setFieldValue}
                        />
                      </div>
                    )}
                    {activeStep === 3 && (
                      <div className="attachments-upload-container">
                        <AttachmentUpload
                          validExtensions={[
                            "jpg",
                            "jpeg",
                            "png",
                            "heic",
                            "mov",
                            "mp4",
                          ]}
                          numberOfFiles={15}
                          currentAttachments={currentgAttachmentsList}
                          newAttachments={newAttachmentsList}
                          deletedAttachments={deletedAttchmentsList}
                          handleLists={handleList}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </Modal>
            );
          }}
        </Formik>
      ) : (
        <Navigate to="/login" />
      )}
    </>
  );
}

interface IMaps {
  center;
  selectedLocation;
}

function Map(props: IMaps) {
  const { center, selectedLocation } = props;
  // const [selected, setSelected] = useState<any>(null);

  return (
    <div className="map-base">
      <div className="places-container"></div>

      <GoogleMap
        zoom={15}
        center={center}
        mapContainerClassName="map-container"
        options={{
          gestureHandling: "cooperative",
          fullscreenControl: false,
          zoomControl: false,
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: false,
          styles: mapsStyle,
        }}
      >
        {selectedLocation && <Marker position={selectedLocation} />}
      </GoogleMap>
    </div>
  );
}

// const PlacesAutocomplete = ({ setSelected, setCenter }) => {
//   const {
//     value,
//     setValue,
//     suggestions: { data },
//     clearSuggestions,
//   } = usePlacesAutocomplete({
//     requestOptions: {
//       componentRestrictions: { country: ["cr"] },
//       language: "es",
//     },
//     defaultValue: "",
//     debounce: 300,
//   });

//   const handleSelect = async (address) => {
//     clearSuggestions();

//     const results = await getGeocode({ address: address.label });
//     const { lat, lng } = await getLatLng(results[0]);
//     setSelected({ lat, lng });
//     setCenter({ lat, lng });
//   };

//   return (
//     <Select
//       classNamePrefix={"new-select"}
//       onChange={handleSelect}
//       inputValue={value}
//       onInputChange={(e) => {
//         setValue(e);
//       }}
//       options={data.map((d) => {
//         return { label: d.description, value: d.place_id };
//       })}
//     ></Select>
//   );
// };

export default NewProperty;
