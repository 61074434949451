import { loginUser, loginUserImage, token } from "../redux/reducers/authSlice";
import { addUsers } from "../redux/reducers/usersSlice";
import { getAllUsers, getUserImage } from "../services/UsersService";
import { IUser } from "../types/user";
import { decrypt } from "../utils/encryptUtils";
import { createJWT } from "../utils/jwtUtils";

export const login = async (dispatch, translator, loginInformation) => {
  const users = await getUsers(dispatch, translator);
  const userToken = await createJWT();
  let isUser = false;
  users.forEach((user) => {
    if (user.document === loginInformation.id) {
      const userPass = user.tempPassword
        ? user.password
        : decrypt(user.password);
      if (userPass === loginInformation.password) {
        dispatch(loginUser(user));
        dispatch(token(userToken));
        isUser = true;
      }
    }
  });
  return isUser;
};

export const logout = (dispatch) => {
  dispatch(loginUser(null));
  dispatch(loginUserImage(""));
  dispatch(addUsers([]));
  dispatch(token(undefined));
};

export const getUImage = async (dispatch, id) => {
  const userImage = await getUserImage(id);
  dispatch(loginUserImage(userImage));
};

export const getUsers = async (dispatch: Function, translator) => {
  const action = (users: IUser[]) => dispatch(addUsers(users));
  const users = await getAllUsers(translator, action);
  return users;
};
