import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { db, storage } from "../config/firebaseSetting";
import { showToast } from "../utils/toast";
import {
  deleteObject,
  getBlob,
  getDownloadURL,
  ref,
  uploadBytes,
} from "firebase/storage";
import { isJWTValid } from "../utils/jwtUtils";
import IProperty from "../types/property";
import IPropertyCounter from "../types/propertyCounter";

const propertiesDocRef = collection(db, "properties");

export const getPropertiesCounter = async () => {
  try {
    const queryRef = query(propertiesDocRef, where("type", "==", "COUNTER"));
    const result = await getDocs(queryRef);
    const data = result.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    })) as IPropertyCounter[];
    return data;
  } catch (err) {
    return [{ type: "COUNTER", value: -1 } as IPropertyCounter];
  }
};

export const getProperties = async (translator: any, token, navigate) => {
  if (isJWTValid(token)) {
    try {
      const queryRef = query(
        propertiesDocRef,
        where("type", "in", ["LAND", "LAND-BUILD", "BUILD"]),
        where("status", "!=", "deleted")
      );
      const result = await getDocs(queryRef);
      const data = result.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      })) as IProperty[];
      return data;
    } catch (err) {
      showToast(
        translator("ToastMessages.error"),
        translator("ToastMessages.genericGetError"),
        "error"
      );
      return [];
    }
  } else {
    navigate("/login");
    return [];
  }
};

export const getVisibleProperties = async () => {
  try {
    const queryRef = query(
      propertiesDocRef,
      where("type", "in", ["LAND", "LAND-BUILD", "BUILD"]),
      where("sold", "==", false),
      where("visible", "==", true),
      where("status", "!=", "deleted")
    );
    const result = await getDocs(queryRef);
    const data = result.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    })) as IProperty[];
    return data;
  } catch (err) {
    return [];
  }
};
export const getAllProperties = async () => {
  try {
    const queryRef = query(
      propertiesDocRef,
      where("type", "in", ["LAND", "LAND-BUILD", "BUILD"]),
      where("status", "!=", "deleted")
    );
    const result = await getDocs(queryRef);
    const data = result.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    })) as IProperty[];
    return data;
  } catch (err) {
    return [];
  }
};

export const getPropertyById = async (propertyId) => {
  try {
    const propertyRef = doc(db, "properties", propertyId);
    const result = await getDoc(propertyRef);
    if (result.exists()) {
      const data = {
        ...result.data(),
        id: result.id,
      } as IProperty;
      return data;
    }
    return undefined;
  } catch (err) {
    return undefined;
  }
};

export const getSoldByUsProperties = async () => {
  try {
    const queryRef = query(
      propertiesDocRef,
      where("type", "in", ["LAND", "LAND-BUILD", "BUILD"]),
      where("sold", "==", true),
      where("soldByUs", "==", true),
      where("status", "!=", "deleted")
    );
    const result = await getDocs(queryRef);
    const data = result.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    })) as IProperty[];
    return data;
  } catch (err) {
    return [];
  }
};

export const addProperty = async (
  property,
  translator,
  toastVisible = true,
  token,
  navigate
) => {
  if (isJWTValid(token)) {
    try {
      const { id, files, ...rest } = property;
      const auxCounter: IPropertyCounter = (await getPropertiesCounter())[0];
      const counter: number = auxCounter.value;
      const auxProperty = { ...rest, consecutive: counter };
      const docRef = await addDoc(propertiesDocRef, auxProperty);
      const newCounter = { ...auxCounter, value: counter + 1 };
      await updatePropertyCounter(newCounter);
      toastVisible &&
        showToast(
          translator("ToastMessages.success"),
          translator("ToastMessages.genericSaveSuccess"),
          "success"
        );
      const result = await getDoc(docRef);
      const data = { ...result.data(), id: result.id } as IProperty;
      return data;
    } catch (e) {
      toastVisible &&
        showToast(
          translator("ToastMessages.error"),
          translator("ToastMessages.genericSaveError"),
          "error"
        );
      return undefined;
    }
  } else {
    navigate("/login");
  }
};

export const updatePropertyCounter = async (propertyCounter) => {
  try {
    const { id, ...rest } = propertyCounter;
    const propertyDocRef = doc(db, "properties", id);
    await setDoc(propertyDocRef, rest);
    return true;
  } catch (e) {
    return false;
  }
};

export const updateProperty = async (
  property,
  translator,
  toastVisible = true,
  token,
  navigate
) => {
  if (isJWTValid(token)) {
    try {
      const { id, files, ...rest } = property;
      let auxRest = { ...rest };
      if (property.files) {
        auxRest = { ...rest, files: files };
      }
      const propertyDocRef = doc(db, "properties", id);
      await setDoc(propertyDocRef, auxRest);
      toastVisible &&
        showToast(
          translator("ToastMessages.success"),
          translator("ToastMessages.genericSaveSuccess"),
          "success"
        );
      return true;
    } catch (e) {
      toastVisible &&
        showToast(
          translator("ToastMessages.error"),
          translator("ToastMessages.genericSaveError"),
          "error"
        );
      return false;
    }
  } else {
    navigate("/login");
    return false;
  }
};

export const savePropertyAttachments = async (propertyId, files: []) => {
  try {
    const urls = await Promise.all(
      files.map(async (file: File) => {
        const storageRef = ref(
          storage,
          `properties/${propertyId}/${file.name}`
        );
        await uploadBytes(storageRef, file);
        const fileURL = await getDownloadURL(storageRef);
        return { name: file.name, url: fileURL };
      })
    );
    return urls;
  } catch (error) {
    return [];
  }
};
export const deletePropertyAttachments = async (propertyId, files: []) => {
  try {
    Promise.all(
      files.map(async (file: File) => {
        const storageRef = ref(
          storage,
          `properties/${propertyId}/${file.name}`
        );
        await deleteObject(storageRef);
      })
    );
  } catch (error) {}
};

export const getPropertyAttachments = async (
  propertyId: string,
  names: any[]
) => {
  try {
    const files = Promise.all(
      names.map(async (name) => {
        const storageRef = ref(storage, `properties/${propertyId}/${name}`);
        const response = await getBlob(storageRef);
        const file = new File([response], name);
        return file;
      })
    );
    return files;
  } catch (error) {
    return [];
  }
};
