import {
  collection,
  doc,
  documentId,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { db, storage } from "../config/firebaseSetting";
import { IUser } from "../types/user";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { showToast } from "../utils/toast";
import { isJWTValid } from "../utils/jwtUtils";

export const getAllUsers = async (translator: any, action: any) => {
  try {
    const queryInfo = collection(db, "users");
    const result = await getDocs(queryInfo);
    const data = result.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    })) as IUser[];
    action && action(data);
    return data;
  } catch (err) {
    return [];
  }
};

export const getUserImage = async (id) => {
  try {
    const storageRef = ref(storage, "profileImages/" + id);
    const url = await getDownloadURL(storageRef);
    return url;
  } catch (err) {
    return undefined;
  }
};

export const updateUser = async (
  user,
  translator,
  toastVisible = true,
  token,
  navigate
) => {
  if (isJWTValid(token)) {
    try {
      const { id, ...rest } = user;
      const userDocRef = doc(db, "users", id);
      await setDoc(userDocRef, rest);
      toastVisible &&
        showToast(
          translator("ToastMessages.success"),
          translator("ToastMessages.genericSaveSuccess"),
          "success"
        );
      return true;
    } catch (e) {
      toastVisible &&
        showToast(
          translator("ToastMessages.error"),
          translator("ToastMessages.genericSaveError"),
          "error"
        );
      return false;
    }
  } else {
    navigate("/login");
  }
};

export const saveProfilePicture = async (file, userId) => {
  try {
    const storageRef = ref(storage, `profileImages/${userId}`);
    await uploadBytes(storageRef, file);
    return true;
  } catch (err) {
    return false;
  }
};

export const ValidateCode = async (
  code,
  userId,
  translator: any,
  token,
  navigate
) => {
  if (isJWTValid(token)) {
    try {
      const queryInfo = collection(db, "users");
      const q = query(queryInfo, where(documentId(), "==", userId));
      const result = await getDocs(q);
      const data = result.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      })) as IUser[];
      if (data.length > 0) {
        if (data[0].pin?.toUpperCase() === code.toUpperCase()) {
          return true;
        } else {
          showToast(
            translator("ToastMessages.warning"),
            translator("ValidatePin.invalidCode"),
            "warning"
          );
          return false;
        }
      }
      return false;
    } catch (err) {
      showToast(
        translator("ToastMessages.error"),
        translator("ValidatePin.errorInValidation"),
        "error"
      );
      return false;
    }
  } else {
    navigate("/login");
  }
};
