import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../config/firebaseSetting";
import { IAppImage } from "../types/appImage";

export const getHomeCarouselImagesPaths = async (translator: any) => {
  try {
    const queryDocRef = collection(db, "appImages");
    const queryInfo = query(
      queryDocRef,
      where("location", "==", "home-carousel")
    );
    const result = await getDocs(queryInfo);
    const data = result.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    })) as IAppImage[];
    return data;
  } catch (err) {
    return [];
  }
};
