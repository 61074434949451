import { createStandaloneToast } from "@chakra-ui/react";

const { toast } = createStandaloneToast();

export const showToast = (
  title: string,
  description: string,
  status: any = "info"
) =>
  toast({
    title,
    description,
    status,
    duration: 3000,
    isClosable: true,
    position: "top-right",
  });
