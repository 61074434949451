import React from "react";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  InputProps,
  InputRightAddon,
  Textarea,
  Tooltip,
  useColorMode,
} from "@chakra-ui/react";
import "./Input.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleInfo,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import { get } from "lodash";

interface IInputProps extends InputProps {
  label: string;
  translator: any;
  ref?: any;
  disabled?: boolean;
  errors?: any;
  validationSchema?: any;
  leftChild?: any;
  tooltipInfo?: string;
  isSecret?: boolean;
  marginTop?: string;
  notShowLabel?: boolean;
  omitRequired?: boolean;
}

function CCInput(props: IInputProps) {
  const { colorMode } = useColorMode();
  const isLightMode = colorMode === "light";
  const [show, setShow] = React.useState(false);
  const { label } = props;
  const { errors } = props;
  const {
    validationSchema,
    leftChild,
    tooltipInfo,
    isSecret,
    notShowLabel,
    omitRequired,
  } = props;
  const iProps: IInputProps | any = {
    type: props.type || "",
    borderColor: props.borderColor || isLightMode ? "#000" : "white",

    variant: props.variant || "outline",
    placeholder: props.placeholder || "",
    ref: props.ref,
    id: props.id,
    disabled: props.disabled,
    value: props.value,
    name: props.name,
    onChange: props.onChange,
    onKeyUp: props.onKeyUp,
  };

  const handleClick = () => setShow(!show);

  return (
    <>
      {iProps.type !== "password" ? (
        iProps.type !== "textArea" ? (
          <div className="input-container">
            <FormControl
              marginTop={props.marginTop}
              isRequired={
                validationSchema?.fields &&
                props.name &&
                !omitRequired &&
                get(validationSchema.fields, props.name)
              }
              isInvalid={props.name && errors[props.name]}
            >
              <div className="input-label">
                {!notShowLabel && (
                  <FormLabel fontWeight={"600"} htmlFor={props.id}>
                    {label}
                  </FormLabel>
                )}
                {tooltipInfo && (
                  <Tooltip
                    borderRadius={"5px"}
                    label={tooltipInfo}
                    hasArrow
                    className="input-tooltip"
                    fontSize="md"
                  >
                    <FontAwesomeIcon icon={faCircleInfo} />
                  </Tooltip>
                )}
              </div>
              {!leftChild ? (
                <Input
                  disabled={props.disabled}
                  className={`input ${
                    !isLightMode ? "input-dark-border" : "input-light-border"
                  }`}
                  {...iProps}
                  onClick={props.onClick}
                  type={isSecret ? "password" : iProps.type}
                />
              ) : (
                <InputGroup>
                  <InputLeftAddon children={leftChild} />
                  <Input
                    disabled={props.disabled}
                    className={`input ${
                      !isLightMode ? "input-dark-border" : "input-light-border"
                    }`}
                    {...iProps}
                  />
                </InputGroup>
              )}
              {props.name && errors[props.name] && (
                <FormErrorMessage>{errors[props.name]}</FormErrorMessage>
              )}
            </FormControl>
          </div>
        ) : (
          <div className="input-container">
            <FormControl
              isRequired={
                validationSchema?.fields &&
                props.name &&
                get(validationSchema.fields, props.name)
              }
              isInvalid={props.name && errors[props.name]}
            >
              <div>
                <FormLabel fontWeight={"600"} htmlFor={props.id}>
                  {label}
                </FormLabel>
                {tooltipInfo && (
                  <Tooltip
                    borderRadius={"5px"}
                    label={tooltipInfo}
                    hasArrow
                    className="input-tooltip"
                    fontSize="md"
                  >
                    <FontAwesomeIcon icon={faCircleInfo} />
                  </Tooltip>
                )}
              </div>
              <Textarea
                disabled={props.disabled}
                className={`input ${props.className} ${
                  !isLightMode ? "input-dark-border" : "input-light-border"
                }`}
                {...iProps}
              />
              {props.name && errors[props.name] && (
                <FormErrorMessage>{errors[props.name]}</FormErrorMessage>
              )}
            </FormControl>
          </div>
        )
      ) : (
        <div className="input-container">
          <FormControl
            isRequired={
              validationSchema?.fields &&
              props.name &&
              get(validationSchema?.fields, props.name)
            }
            isInvalid={props.name && errors[props.name]}
          >
            <FormLabel htmlFor={props.id}>{label}</FormLabel>
            <InputGroup size="md">
              <Input
                disabled={props.disabled}
                className={`input ${
                  !isLightMode ? "input-dark-border" : "input-light-border"
                }`}
                {...iProps}
                type={show ? "text" : "password"}
              />
              <InputRightAddon
                onClick={handleClick}
                className="show-hide-addon togglePassword"
                cursor={"pointer"}
                children={
                  show ? (
                    <FontAwesomeIcon icon={faEyeSlash} />
                  ) : (
                    <FontAwesomeIcon icon={faEye} />
                  )
                }
              />
            </InputGroup>
            {props.name && errors[props.name] && (
              <FormErrorMessage>{errors[props.name]}</FormErrorMessage>
            )}
          </FormControl>
        </div>
      )}
    </>
  );
}
export default CCInput;
