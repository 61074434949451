import { collection, getDocs } from "firebase/firestore";
import { db } from "../config/firebaseSetting";
import { IFeatures } from "../types/features";
import { IAmenities } from "../types/amenities";

export const getFeatures = async (translator: any) => {
  try {
    const queryInfo = collection(db, "features");
    const result = await getDocs(queryInfo);
    const data = result.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    })) as IFeatures[];
    return data;
  } catch (err) {
    return [];
  }
};
export const getAmenities = async (translator: any) => {
  try {
    const queryInfo = collection(db, "amenities");
    const result = await getDocs(queryInfo);
    const data = result.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    })) as IAmenities[];
    return data;
  } catch (err) {
    return [];
  }
};
