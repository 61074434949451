import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import App from "./app";
import { ChakraProvider } from "@chakra-ui/react";
import { theme } from "./styles/theme";
import LangProvider from "./providers/LanguageProvider";
import { PersistGate } from "redux-persist/integration/react";
import persistStore from "redux-persist/es/persistStore";

const persistor = persistStore(store);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ChakraProvider theme={theme}>
        <PersistGate persistor={persistor}>
          <LangProvider>
            <App />
          </LangProvider>
        </PersistGate>
      </ChakraProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("app")
);
