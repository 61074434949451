/* eslint-disable react/jsx-no-target-blank */
import { Box, Img, Stack } from "@chakra-ui/react";
import "./Contact.scss";
import CCInput from "../../layouts/Input/Input";
import { Formik } from "formik";
import * as yup from "yup";
import useTranslations from "../../../hooks/useTranslations";
import Button from "../../layouts/Button";
import emailjs from "@emailjs/browser";
import { useState } from "react";
import Spinner from "../../layouts/Spinner";
import { showToast } from "../../../utils/toast";
import useIsMobile from "../../../hooks/useIsMobile";
import { facebookIcon, instagramIcon } from "../../../assets/icons/icons";

export default function Contact() {
  const translator = useTranslations();
  const [sendingEmail, setSendingEmail] = useState<boolean>(false);
  const isMobile = useIsMobile();

  const initialValues = {
    fullName: "",
    email: "",
    phoneNumber: "",
    subject: "",
    message: "",
  };

  const validationSchema = yup.object().shape({
    fullName: yup.string().required(translator("Global.requieredField")),
    email: yup.string().email(translator("Global.invalidEmail")),
    phoneNumber: yup
      .number()
      .typeError(translator("Global.invalidPhone"))
      .required(translator("Global.requieredField")),
    subject: yup.string().required(translator("Global.requieredField")),
    message: yup.string().required(translator("Global.requieredField")),
  });

  return (
    <div
      className="contact-base"
      style={{
        backgroundImage: `${!isMobile ? "url(/property_image.jpg)" : ""}`,
      }}
    >
      <div className="contact-container">
        <div className="contact-info">
          <div className="contact-info-left">
            <div className="sections-3"></div>
            <div className="sections-3 center-section">
              <Img
                borderRadius="full"
                boxSize="100px"
                minWidth={"100px"}
                src={require("../../../assets/images/circular_logo.png")}
              ></Img>
              <p className="contact-text">{translator("Contact.letUs")}</p>
              <p className="contact-text">{translator("Contact.contactUs")}</p>
            </div>
            <div className="down-section sections-3">
              <a
                className="link-with-icon"
                href="https://www.instagram.com/sc_realestate_cr/"
                target="_blank"
              >
                <div>
                  {instagramIcon()}
                  Instagram
                </div>
              </a>
              <a
                className="link-with-icon"
                href="https://www.facebook.com/SyC.Real.Estate/"
                target="_blank"
              >
                <div>
                  {facebookIcon()}
                  Facebook
                </div>
              </a>
            </div>
          </div>
          <div className="contact-info-right">
            {sendingEmail && (
              <div className="sending-email">
                <Spinner label={translator("Global.sendingEmail")}></Spinner>
              </div>
            )}
            <p className="contact-title">{translator("Global.contact")}</p>
            <Formik
              validationSchema={validationSchema}
              validateOnBlur
              validateOnChange
              initialValues={initialValues}
              onSubmit={(values, actions) => {
                setSendingEmail(true);
                emailjs
                  .send(
                    "service_jxzh4ns",
                    "template_ayxxjni",
                    {
                      name: values.fullName,
                      email: values.email,
                      phone: values.phoneNumber,
                      subject: values.subject,
                      message: values.message,
                    },
                    "Ng5-shlzPDNrRGjU9"
                  )
                  .then((response) => {
                    setSendingEmail(false);
                    if (response.status === 200) {
                      actions.resetForm();
                      showToast(
                        translator("ToastMessages.success"),
                        translator("Global.successEmail"),
                        "success"
                      );
                    } else {
                      showToast(
                        translator("ToastMessages.error"),
                        translator("Global.errorEmail"),
                        "error"
                      );
                    }
                  });
              }}
            >
              {(props) => {
                return (
                  <div className="form-container">
                    <Stack spacing={"12px"}>
                      <Box>
                        <CCInput
                          label={translator("Global.fullName")}
                          translator={translator}
                          value={props.values.fullName}
                          validationSchema={validationSchema}
                          errors={props.errors}
                          name="fullName"
                          onChange={(e) => {
                            props.setFieldValue(
                              "fullName",
                              e.currentTarget.value
                            );
                          }}
                        ></CCInput>
                      </Box>
                      <Box>
                        <CCInput
                          label={translator("Global.email")}
                          translator={translator}
                          value={props.values.email}
                          validationSchema={validationSchema}
                          errors={props.errors}
                          name="email"
                          onChange={(e) => {
                            props.setFieldValue("email", e.currentTarget.value);
                          }}
                        ></CCInput>
                      </Box>
                      <Box>
                        <CCInput
                          label={translator("Global.phoneNumber")}
                          translator={translator}
                          value={props.values.phoneNumber}
                          validationSchema={validationSchema}
                          errors={props.errors}
                          name="phoneNumber"
                          onChange={(e) => {
                            props.setFieldValue(
                              "phoneNumber",
                              e.currentTarget.value
                            );
                          }}
                        ></CCInput>
                      </Box>
                      <Box>
                        <CCInput
                          label={translator("Global.subject")}
                          translator={translator}
                          value={props.values.subject}
                          validationSchema={validationSchema}
                          errors={props.errors}
                          name="subject"
                          onChange={(e) => {
                            props.setFieldValue(
                              "subject",
                              e.currentTarget.value
                            );
                          }}
                        ></CCInput>
                      </Box>

                      <Box>
                        <CCInput
                          className="no-resize"
                          resize={"block"}
                          label={translator("Global.message")}
                          type="textArea"
                          translator={translator}
                          value={props.values.message}
                          validationSchema={validationSchema}
                          errors={props.errors}
                          name="message"
                          onChange={(e) => {
                            props.setFieldValue(
                              "message",
                              e.currentTarget.value
                            );
                          }}
                        ></CCInput>
                      </Box>
                    </Stack>
                    <Button onClick={props.submitForm} width={"full"}>
                      {translator("Global.send")}
                    </Button>
                  </div>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}
