import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  ModalProps,
} from "@chakra-ui/react";
import { ReactElement } from "react";

interface IModalProps extends ModalProps {
  showCloseButton?: boolean;
  modalHeader?: ReactElement | string;
  footer?: ReactElement | string;
  maxW?: string;
  minH?: string;
}
function CCModal(props: IModalProps) {
  const {
    onClose,
    size = "xl",
    isOpen,
    children,
    footer,
    modalHeader,
    showCloseButton,
    maxW,
    minH,
  } = props;

  return (
    <Modal onClose={onClose} size={size} isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent maxW={maxW} minH={minH}>
        {modalHeader && (
          <ModalHeader borderBottomWidth="1px">{modalHeader}</ModalHeader>
        )}
        {showCloseButton && <ModalCloseButton />}
        <ModalBody>{children}</ModalBody>
        <ModalFooter>{footer}</ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default CCModal;
