/* eslint-disable react-hooks/exhaustive-deps */
import { Tooltip, useColorMode } from "@chakra-ui/react";
import {
  faCircleXmark,
  faCloudArrowUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FileIcon, defaultStyles } from "react-file-icon";
import { showToast } from "../../../utils/toast";
import useTranslations from "../../../hooks/useTranslations";
import { Col } from "reactstrap";
import mime from "mime";
import * as yup from "yup";
import Dropzone from "react-dropzone";
import "./AttachmentUpload.scss";
import { get } from "lodash";

interface AttachmentProps {
  file: any;
  validExtensions: Array<string>;
  title: string;
  index: any;
  currentAttachments: Array<any>;
  newAttachments: Array<any>;
  deletedAttachments: Array<any>;
  handleLists: (
    currentList: Array<any>,
    newList: Array<any>,
    deletedList: Array<any>
  ) => void;
}

function Attachment(props: AttachmentProps) {
  const {
    file,
    title,
    index,
    currentAttachments,
    newAttachments,
    deletedAttachments,
    handleLists,
  } = props;

  const extension = title.split(".").pop();

  return (
    <Tooltip
      borderRadius={"5px"}
      label={title}
      hasArrow
      className="input-tooltip"
      fontSize="md"
    >
      <div className="attachment" id={index}>
        <div
          className="attachment__remove"
          onClick={() => {
            const fileName: string = file.name;
            const currentAttachedFilesNames: string[] = currentAttachments.map(
              (attachment) => {
                return attachment.name || "";
              }
            );
            const newAttachedFilesNames: string[] = newAttachments.map(
              (attachment) => {
                return attachment.name || "";
              }
            );

            if (currentAttachedFilesNames.includes(fileName)) {
              handleLists(
                currentAttachments.filter((a) => {
                  return a.name !== fileName;
                }),
                newAttachments,
                [...deletedAttachments, file]
              );
            }
            if (newAttachedFilesNames.includes(fileName)) {
              handleLists(
                currentAttachments,
                newAttachments.filter((a) => {
                  return a.name !== fileName;
                }),
                [...deletedAttachments, file]
              );
            }
          }}
        >
          <FontAwesomeIcon icon={faCircleXmark} style={{ color: "#ab1f2d" }} />
        </div>
        <FileIcon extension={extension} {...get(defaultStyles, extension!)} />
        <div className="attachment__title--shorten">{title}</div>
      </div>
    </Tooltip>
  );
}

interface IAttachmentUpload {
  validExtensions: Array<string>;
  numberOfFiles: number;
  currentAttachments: Array<any>;
  newAttachments: Array<any>;
  deletedAttachments: Array<any>;
  handleLists: (
    currentList: Array<any>,
    newList: Array<any>,
    deletedList: Array<any>
  ) => void;

  onChange?: () => {};
}

function AttachmentUpload(props: IAttachmentUpload) {
  const translator = useTranslations();
  const {
    currentAttachments,
    newAttachments,
    deletedAttachments,
    validExtensions,
    numberOfFiles,
    handleLists,
  } = props;
  const { colorMode } = useColorMode();
  const isLightMode = colorMode === "light";

  const onDrop = async (allAccepted: Array<File>) => {
    const types: Array<string> = validExtensions;
    let currentAttachmentsAux = [...currentAttachments];
    let newAttachmentsAux = [...newAttachments];
    let deletedAttachmentsAux = [...deletedAttachments];

    const schema = yup.object().shape({
      file: yup
        .mixed()
        .test(
          "size",
          translator("AttachmentUpload.fileTooLarge"),
          (f: any) => f?.size <= 130000000
        )
        .test(
          "format",
          translator("AttachmentUpload.selectedFormatNoSuported"),
          (f: any) => f && types.includes(mime.getExtension(f?.type)!)
        ),
    });

    await Promise.all(
      allAccepted.map(async (accepted) => {
        const fileName: string = accepted.name;
        const currentAttachedFilesNames: string[] = currentAttachmentsAux.map(
          (attachment) => {
            return attachment.name || "";
          }
        );
        const newAttachedFilesNames: string[] = newAttachmentsAux.map(
          (attachment) => {
            return attachment.name || "";
          }
        );
        const deletedAttachedFilesNames: string[] = deletedAttachmentsAux.map(
          (attachment) => {
            return attachment.name || "";
          }
        );

        try {
          await schema.validate({ file: accepted });

          if (
            currentAttachedFilesNames.includes(fileName) ||
            newAttachedFilesNames.includes(fileName)
          ) {
            throw new Error(translator("AttachmentUpload.alreadyAttachedFile"));
          }
          if (deletedAttachedFilesNames.includes(fileName)) {
            newAttachmentsAux = [...newAttachmentsAux, accepted];
            deletedAttachmentsAux = deletedAttachmentsAux.filter((a) => {
              return a.name !== fileName;
            });
          }
          if (!deletedAttachedFilesNames.includes(fileName)) {
            newAttachmentsAux = [...newAttachmentsAux, accepted];
          }
        } catch (error: any) {
          showToast(translator("ToastMessages.error"), error.message, "error");
        }
      })
    ).then(() => {
      handleLists(
        currentAttachmentsAux,
        newAttachmentsAux,
        deletedAttachmentsAux
      );
    });
  };

  const disabled =
    [...currentAttachments, ...newAttachments].length >= numberOfFiles;

  return (
    <div className="form-group currentAttachments">
      <div className="component-base">
        {
          <Col xs="auto">
            <div
              className={`upload-container${isLightMode ? "" : "-dark"} ${
                disabled ? "uploads-disabled" : ""
              }`}
            >
              <Dropzone disabled={disabled} onDrop={onDrop}>
                {({ getRootProps, getInputProps, isDragActive }) => (
                  <section>
                    <div
                      className={`
                          dropzone-box
                          ${isDragActive ? "active" : ""}
                          ${disabled ? "uploads-disabled" : ""}
                        `}
                      {...(!disabled ? getRootProps({}) : null)}
                    >
                      {!disabled ? (
                        <>
                          <input {...getInputProps()} />
                          <FontAwesomeIcon
                            className="dropzone-icon"
                            icon={faCloudArrowUp}
                          />
                          <div className="dropzone-message">
                            {translator("AttachmentUpload.dropzoneInfo")}
                          </div>
                        </>
                      ) : (
                        <p className="dropzone-box-disabled-text">
                          {translator("AttachmentUpload.disabledDropZoneText")}
                        </p>
                      )}
                    </div>
                  </section>
                )}
              </Dropzone>
              <div className="upload-file-types">
                <div className="file-types-header">
                  {translator("AttachmentUpload.allowedExtensions")}{" "}
                </div>
                <div className="file-types-wrapper">
                  <div className="file-types">
                    {validExtensions.map((ext, index) => {
                      return (
                        <div key={index} className="file-type">
                          {ext}
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="file-limits">
                  150MB {translator("AttachmentUpload.maximum")}
                </div>
                <div className="file-limits">
                  200 x 200 {translator("AttachmentUpload.minimum")}
                </div>
                <div className="file-limits">
                  {translator("AttachmentUpload.maxFiles")} {numberOfFiles}{" "}
                  {translator("EventRfp.files")}
                </div>
              </div>
            </div>
          </Col>
        }
        <div className="files-base">
          {[...currentAttachments, ...newAttachments].length > 0 &&
            [...currentAttachments, ...newAttachments]?.map((a, index) => {
              return (
                <Col xs="auto" key={index}>
                  <Attachment
                    index={`attachment-${index}`}
                    validExtensions={validExtensions}
                    title={a.name}
                    file={a}
                    currentAttachments={currentAttachments}
                    newAttachments={newAttachments}
                    deletedAttachments={deletedAttachments}
                    handleLists={handleLists}
                  />
                </Col>
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default AttachmentUpload;
