/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useRef, useState } from "react";
import useTranslations from "../../../hooks/useTranslations";
import "./Favorites.scss";
import { IAmenities } from "../../../types/amenities";
import { IFeatures } from "../../../types/features";
import {
  getAmenities,
  getFeatures,
} from "../../../services/FeaturesAmenitiesService";
import IProperty from "../../../types/property";
import { parseCurrentPageItems } from "../../../utils/common";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  changeFavoritesProperties,
  changeIsLoading,
} from "../../../redux/reducers/appSettingsSlice";
import { getAllProperties } from "../../../services/PropertiesService";
import PropertyCard from "../Properties/PropertyCard";
import { useNavigate } from "react-router-dom";
import Pagination from "../../common/Pagination";
import { differenceBy, intersectionBy } from "lodash";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Img,
} from "@chakra-ui/react";

const Favorites = () => {
  const [propertiesList, setPropertiesList] = useState<IProperty[]>([]);
  const [favoritesPropertiesList, setFavoritesPropertiesList] = useState<
    IProperty[]
  >([]);
  const [deletedPropertiesList, setDeletedPropertiesList] = useState<
    IProperty[]
  >([]);
  const [favoritesCount, setFavoritesCount] = useState<number>(
    propertiesList.length
  );
  const [amenities, setAmenities] = useState<IAmenities[]>([]);
  const [features, setFeatures] = useState<IFeatures[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [isDeleteFavoritesAlertOpen, setIsDeleteFavoritesAlertOpen] =
    useState<boolean>(false);

  const translator = useTranslations();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const baseLanguage = useAppSelector((state) => state.appSettings.language);
  const savedFavoritesProperties = useAppSelector(
    (state) => state.appSettings.favoritesProperties
  );

  const cancelDeleteRef = useRef<any>();

  const itemsPerPage = 12;

  const toogleIsLoading = (isLoading) => {
    dispatch(changeIsLoading(isLoading));
  };

  const getAmenitiesData = async () => {
    const data: IAmenities[] = await getAmenities(translator);
    setAmenities(data);
  };
  const getFeaturesData = async () => {
    const data: IFeatures[] = await getFeatures(translator);
    setFeatures(data);
  };

  const getPropertiesData = async () => {
    if (savedFavoritesProperties.length <= 0) {
      setFavoritesPropertiesList([]);
      return [];
    }
    toogleIsLoading(true);
    const allProperties: IProperty[] = await getAllProperties()!;
    let auxPropertiesList = [...allProperties];
    const auxFavoritesUpdatedInfo = intersectionBy(
      allProperties,
      savedFavoritesProperties,
      "id"
    );

    const auxDeletedFavorites = [
      ...auxFavoritesUpdatedInfo.filter((fu) => fu.sold),
    ];
    setPropertiesList(auxPropertiesList);
    setFavoritesPropertiesList(auxFavoritesUpdatedInfo);
    setDeletedPropertiesList(auxDeletedFavorites);

    toogleIsLoading(false);
    if (auxDeletedFavorites.length > 0) {
      setIsDeleteFavoritesAlertOpen(true);
    }
    return auxPropertiesList;
  };

  const checkRenderPagination = () =>
    propertiesList.length > itemsPerPage &&
    propertiesList.length === favoritesCount &&
    favoritesCount > itemsPerPage;

  const handleOnPageChange = (pageNumber: number, itemsPerPage: number) => {
    setCurrentPage(pageNumber);
  };

  const items: any = useMemo(() => {
    const auxItems = [...favoritesPropertiesList];
    setFavoritesCount(auxItems.length);
    if (propertiesList.length !== auxItems.length) {
      return auxItems;
    }
    return parseCurrentPageItems(auxItems, currentPage, itemsPerPage);
  }, [propertiesList, favoritesPropertiesList, currentPage, itemsPerPage]);

  useEffect(() => {
    getPropertiesData();
    getAmenitiesData();
    getFeaturesData();
  }, []);

  useEffect(() => {
    setFavoritesPropertiesList(savedFavoritesProperties);
  }, [savedFavoritesProperties]);

  return (
    <>
      {savedFavoritesProperties.length > 0 && (
        <div className="favorites-view-container">
          <div className="favorites-view-header">
            <p className="title">{translator("Favorites.title")}</p>
            <p className="description">{translator("Favorites.description")}</p>
            <AlertDialog
              isOpen={isDeleteFavoritesAlertOpen}
              leastDestructiveRef={cancelDeleteRef}
              onClose={() => {
                setIsDeleteFavoritesAlertOpen(false);
              }}
            >
              <AlertDialogOverlay>
                <AlertDialogContent>
                  <AlertDialogHeader fontSize="lg" fontWeight="bold">
                    {translator("Favorites.deleteSoldFavoritesTitle")}
                  </AlertDialogHeader>

                  <AlertDialogBody>
                    {translator("Favorites.deleteSoldFavoritesMsg")}
                  </AlertDialogBody>

                  <AlertDialogFooter>
                    <Button
                      variant={"solid"}
                      ref={cancelDeleteRef}
                      onClick={() => {
                        setIsDeleteFavoritesAlertOpen(false);
                      }}
                    >
                      {translator("Global.cancel")}
                    </Button>
                    <Button
                      colorScheme="red"
                      onClick={async () => {
                        const auxNewFavorites = differenceBy(
                          favoritesPropertiesList,
                          deletedPropertiesList,
                          "id"
                        );
                        dispatch(
                          changeFavoritesProperties([...auxNewFavorites])
                        );
                        setDeletedPropertiesList([]);
                        setIsDeleteFavoritesAlertOpen(false);
                      }}
                      ml={3}
                    >
                      {translator("Global.delete")}
                    </Button>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialogOverlay>
            </AlertDialog>
          </div>
          <div className="favorites-list-container">
            {items.map((property) => {
              return (
                <PropertyCard
                  property={property}
                  baseLanguage={baseLanguage}
                  translator={translator}
                  navigate={navigate}
                  action={() => {}}
                  features={features}
                  amenities={amenities}
                />
              );
            })}
          </div>
          <div className="pagination-container">
            {checkRenderPagination() && (
              <Pagination
                totalCount={propertiesList.length}
                itemsPerPage={itemsPerPage}
                handleOnPageChange={handleOnPageChange}
                currentPage={currentPage}
                filteredCount={favoritesPropertiesList.length}
              />
            )}
          </div>
        </div>
      )}
      {savedFavoritesProperties.length <= 0 && (
        <div className="noFavorites-container">
          <Img
            width={"200px"}
            src={require("./../../../assets/gifs/heart.gif")}
          />
          <p className="no-favorites-title">
            {translator("Favorites.noFavoritesTitle")}
          </p>
          <p className="no-favorites-desc">
            {translator("Favorites.noFavoritesDesc")}
          </p>
        </div>
      )}
    </>
  );
};

export default Favorites;
