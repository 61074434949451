import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUser } from "../../types/user";

interface sessionState {
  user: IUser | null;
  userAPI: any;
  userImage: string;
  token: any;
}

const initialState: sessionState = {
  user: null,
  userAPI: null,
  userImage: "",
  token: null,
};

export const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    changeUser: (state, { payload }: PayloadAction<IUser>) => {
      state.user = payload;
    },
    loginUser: (state, action) => {
      state.user = action.payload;
    },
    loginUserImage: (state, action) => {
      state.userImage = action.payload;
    },
    token: (state, action) => {
      state.token = action.payload;
    },
    logout: (state) => {
      state.user = null;
      state.token = null;
    },
  },
});

export const { changeUser, loginUser, logout, token, loginUserImage } =
  sessionSlice.actions;

export default sessionSlice.reducer;
