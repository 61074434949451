import { useAppSelector } from "../redux/hooks";
import { get } from "lodash";

const Translator = () => {
  const dictionary = useAppSelector((state) => state.appSettings.translations);

  const translator = (key: string) => {
    return get(dictionary, key);
  };

  return translator;
};

export default Translator;
