/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { changeAppTranslations } from "../redux/reducers/appSettingsSlice";

import en from "../assets/i18n/languages/en.json";
import es from "../assets/i18n/languages/es.json";

const LangProvider: React.FC<any> = (props) => {
  const dispatch = useAppDispatch();
  const language = useAppSelector((state) => state.appSettings.language);
  const [isLoaded, setLoaded] = useState(false);

  const changeLanguage = useMemo(() => {
    switch (language) {
      case "en":
        return en;
      case "es":
      default:
        return es;
    }
  }, [language]);

  useEffect(() => {
    dispatch(changeAppTranslations(changeLanguage));
    if (!isLoaded) {
      setLoaded(true);
    }
  }, [changeLanguage]);

  return <>{isLoaded ? props.children : null}</>;
};

export const supportedLangs: Array<string> = ["es", "en"];

export default LangProvider;
