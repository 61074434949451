/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  FormControl,
  FormLabel,
  IconButton,
  Img,
  RangeSlider,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  RangeSliderTrack,
} from "@chakra-ui/react";
import {
  faFilter,
  faFilterCircleXmark,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Formik } from "formik";
import { keys, get, intersection, max, assign } from "lodash";
import CCButton from "../../layouts/Button/Button";
import "./Properties.scss";
import React, { useEffect, useMemo, useRef, useState } from "react";
import useTranslations from "../../../hooks/useTranslations";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import IProperty from "../../../types/property";
import locations from "../../../assets/locations/locations.json";
import Input from "../../layouts/Input";
import Select from "react-select";
import { changeIsLoading } from "../../../redux/reducers/appSettingsSlice";
import { getVisibleProperties } from "../../../services/PropertiesService";
import { useNavigate } from "react-router-dom";
import PropertyCard from "./PropertyCard";
import { IAmenities } from "../../../types/amenities";
import {
  getAmenities,
  getFeatures,
} from "../../../services/FeaturesAmenitiesService";
import { IFeatures } from "../../../types/features";
import Pagination from "../../common/Pagination";
import { parseCurrentPageItems } from "../../../utils/common";
import { getCurrencyFormat } from "../../../utils/digitsUtils";
import queryString from "query-string";
import { closeIcon } from "../../../assets/icons/icons";
import useIsMobile from "../../../hooks/useIsMobile";
import Drawer from "../../layouts/Drawer";

interface IFiltersForm {
  ref: any;
  translator: Function;
  navigate: Function;
  setIsDrawerOpen: Function;
  buildOptionsPath: Function;
  initialValues: any;
  isMobile: any;
  SortOptions: any;
  maxMinPrice: any;
  amenities: any;
  provinces: any;
  handleTypeTranslate: Function;
  baseLanguage: string;
  items: any;
}
const FiltersForm = (props: IFiltersForm) => {
  const {
    ref,
    translator,
    navigate,
    setIsDrawerOpen,
    buildOptionsPath,
    initialValues,
    isMobile,
    SortOptions,
    maxMinPrice,
    amenities,
    provinces,
    handleTypeTranslate,
    baseLanguage,
    items,
  } = props;

  return (
    <Formik
      innerRef={ref}
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(values) => {
        setIsDrawerOpen(false);
        navigate(`/properties?${buildOptionsPath(values)}`);
      }}
    >
      {({ values, setFieldValue, submitForm }) => {
        return (
          <div className="filter-bar">
            <div className="filters-title">
              <h4>{translator("Global.filterBy")}</h4>
            </div>
            <div className="filters-base">
              {isMobile && items.length > 0 && <SortOptions />}
              <div className="input-container">
                <Input
                  label={translator("AdmProperties.nameRef")}
                  value={values.nameRef}
                  translator={translator}
                  onChange={(e) => {
                    setFieldValue("nameRef", e.currentTarget.value);
                  }}
                />
              </div>
              <div className="input-container">
                <FormControl>
                  <FormLabel fontWeight={"600"}>
                    {translator("Global.price")}
                  </FormLabel>
                  <p className="range-label">{`${translator(
                    "Global.range"
                  )}: ${getCurrencyFormat(
                    "es-CR",
                    "CRC",
                    values.price[0]
                  )} - ${getCurrencyFormat(
                    "es-CR",
                    "CRC",
                    values.price[1]
                  )}`}</p>

                  <div className="slider-container">
                    <RangeSlider
                      value={values.price}
                      onChange={(val) => setFieldValue("price", val)}
                      min={maxMinPrice[0]}
                      max={maxMinPrice[1]}
                      step={100000}
                    >
                      <RangeSliderTrack bg={"#CCCCCC"}>
                        <RangeSliderFilledTrack bg="secondary_color" />
                      </RangeSliderTrack>
                      <RangeSliderThumb boxSize={6} index={0}>
                        <Box />
                      </RangeSliderThumb>
                      <RangeSliderThumb boxSize={6} index={1}>
                        <Box />
                      </RangeSliderThumb>
                    </RangeSlider>
                  </div>
                </FormControl>
              </div>
              <div className="input-container">
                <FormControl>
                  <FormLabel fontWeight={"600"}>
                    {translator("Global.type")}
                  </FormLabel>
                  <div className="select-clear-option">
                    <div
                      className="remove-icon-container"
                      onClick={() => {
                        setFieldValue("type", null);
                      }}
                    >
                      {values.type && closeIcon()}
                    </div>
                    <Select
                      classNamePrefix={"new-select"}
                      placeholder={translator("Global.select")}
                      getOptionLabel={(option) =>
                        `${handleTypeTranslate(option.value)}`
                      }
                      value={values.type}
                      options={[
                        {
                          value: "LAND",
                          label: translator("Global.land"),
                        },
                        {
                          value: "BUILD",
                          label: translator("Global.construction"),
                        },
                        {
                          value: "LAND-BUILD",
                          label: translator("Global.landConst"),
                        },
                      ]}
                      onChange={(option) => {
                        setFieldValue("type", option);
                      }}
                    />
                  </div>
                </FormControl>
              </div>
              <div className="input-container">
                <FormLabel fontWeight={"600"}>
                  {translator("Global.amenities")}
                </FormLabel>
                <Select
                  classNamePrefix={"new-select"}
                  placeholder={translator("Global.select")}
                  getOptionLabel={(option) => {
                    const amenitie = amenities.find((am) => {
                      return am.id === option.value;
                    });
                    return `${
                      baseLanguage === "es" ? amenitie?.name : amenitie?.name_en
                    }`;
                  }}
                  isMulti
                  value={values.amenities}
                  options={amenities.map((a, i) => {
                    return {
                      label: baseLanguage === "es" ? a.name : a.name_en,
                      value: a.id,
                    } as any;
                  })}
                  onChange={(options) => {
                    setFieldValue("amenities", options);
                  }}
                />
              </div>
              <div className="input-container">
                <FormControl>
                  <FormLabel fontWeight={"600"}>
                    {translator("Global.province")}
                  </FormLabel>
                  <div className="select-clear-option">
                    <div
                      className="remove-icon-container"
                      onClick={() => {
                        setFieldValue("canton", null);
                        setFieldValue("district", null);
                        setFieldValue("province", null);
                      }}
                    >
                      {values.province && closeIcon()}
                    </div>
                    <Select
                      classNamePrefix={"new-select"}
                      placeholder={translator("Global.select")}
                      value={values.province}
                      onChange={(e) => {
                        setFieldValue("canton", null);
                        setFieldValue("district", null);
                        setFieldValue("province", e);
                      }}
                      options={[
                        ...keys(provinces).map((p) => {
                          const province = provinces[p];
                          return { value: p, label: province.name };
                        }),
                      ]}
                    />
                  </div>
                </FormControl>
              </div>
              <div className="input-container">
                <FormControl>
                  <FormLabel fontWeight={"600"}>
                    {translator("Global.canton")}
                  </FormLabel>
                  <div className="select-clear-option">
                    <div
                      className="remove-icon-container"
                      onClick={() => {
                        setFieldValue("canton", null);
                        setFieldValue("district", null);
                      }}
                    >
                      {values.canton && closeIcon()}
                    </div>
                    <Select
                      classNamePrefix={"new-select"}
                      placeholder={translator("Global.select")}
                      value={values.canton}
                      onChange={(e) => {
                        setFieldValue("district", null);
                        setFieldValue("canton", e);
                      }}
                      options={
                        get(values.province, "value") !== ""
                          ? [
                              ...keys(
                                get(
                                  locations,
                                  `provinces.${get(
                                    values.province,
                                    "value"
                                  )}.cantons`
                                )
                              ).map((c) => {
                                const canton: any = get(
                                  locations,
                                  `provinces.${get(
                                    values.province,
                                    "value"
                                  )}.cantons`
                                )![c];
                                return {
                                  value: c,
                                  label: canton.name,
                                };
                              }),
                            ]
                          : []
                      }
                    />
                  </div>
                </FormControl>
              </div>
              <div className="input-container">
                <FormControl>
                  <FormLabel fontWeight={"600"}>
                    {translator("Global.district")}
                  </FormLabel>
                  <div className="select-clear-option">
                    <div
                      className="remove-icon-container"
                      onClick={() => {
                        setFieldValue("district", null);
                      }}
                    >
                      {values.district && closeIcon()}
                    </div>
                    <Select
                      classNamePrefix={"new-select"}
                      placeholder={translator("Global.select")}
                      value={values.district}
                      onChange={(e) => {
                        setFieldValue("district", e);
                      }}
                      options={
                        get(values.canton, "value") !== ""
                          ? [
                              ...keys(
                                get(
                                  locations,
                                  `provinces.${get(
                                    values.province,
                                    "value"
                                  )}.cantons.${get(
                                    values.canton,
                                    "value"
                                  )}.districts`
                                )
                              ).map((c) => {
                                const district: any = get(
                                  locations,
                                  `provinces.${get(
                                    values.province,
                                    "value"
                                  )}.cantons.${get(
                                    values.canton,
                                    "value"
                                  )}.districts`
                                )![c];
                                return { value: c, label: district };
                              }),
                            ]
                          : []
                      }
                    />
                  </div>
                </FormControl>
              </div>
              <div className="buttons-container">
                <CCButton
                  width={"full"}
                  rightIcon={<FontAwesomeIcon icon={faSearch} />}
                  onClick={() => {
                    submitForm();
                  }}
                >
                  {translator("Global.search")}
                </CCButton>
                <CCButton
                  width={"full"}
                  rightIcon={<FontAwesomeIcon icon={faFilterCircleXmark} />}
                  onClick={() => {
                    setFieldValue("nameRef", "");
                    setFieldValue("province", null);
                    setFieldValue("canton", null);
                    setFieldValue("district", null);
                    setFieldValue("type", null);
                    setFieldValue("amenities", null);
                    setFieldValue("price", [maxMinPrice[0], maxMinPrice[1]]);
                    submitForm();
                  }}
                >
                  {translator("AdmProperties.clearFilters")}
                </CCButton>
              </div>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

const Properties: React.FC<any> = () => {
  const [propertiesList, setPropertiesList] = useState<IProperty[]>([]);
  const [propertiesListFiltered, setPropertiesListFiltered] = useState<
    IProperty[]
  >([]);
  const [amenities, setAmenities] = useState<IAmenities[]>([]);
  const [features, setFeatures] = useState<IFeatures[]>([]);
  const [filteredCount, setFilteredCount] = useState<number>(
    propertiesList.length
  );
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [maxMinPrice, setMaxMinPrice] = useState<number[]>([0, 0]);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const itemsPerPage = 12;

  const search = useMemo(
    () => queryString.parse(window.location.search),
    [window.location.search]
  );

  const handleSortTranslate = (sortOpt: any) => {
    switch (sortOpt) {
      case "NAME-DESC":
        return translator("Properties.sortByNameDesc");
      case "NAME-ASC":
        return translator("Properties.sortByNameAsc");
      case "PRICE-DESC":
        return translator("Properties.sortByPriceDesc");
      case "PRICE-ASC":
        return translator("Properties.sortByPriceDesc");
    }
  };

  const translator = useTranslations();
  const isMobile = useIsMobile();
  const [sortOption, setSortOption] = useState<any>(
    search.sort
      ? { label: handleSortTranslate(search.sort), value: search.sort }
      : {
          label: translator("Properties.sortByNameDesc"),
          value: "NAME-DESC",
        }
  );
  const provinces = get(locations, "provinces");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const baseLanguage = useAppSelector((state) => state.appSettings.language);

  const ref = useRef<any>();

  const toogleIsLoading = (isLoading) => {
    dispatch(changeIsLoading(isLoading));
  };

  const handleTypeTranslate = (type: any) => {
    switch (type) {
      case "BUILD":
        return translator("Global.construction");
      case "LAND":
        return translator("Global.land");
      case "LAND-BUILD":
        return translator("Global.landConst");
    }
  };

  const initialValues = {
    nameRef: search.nameRef ? String(search.nameRef) : "",
    province: search.province
      ? {
          label: get(locations, `provinces.${search.province}.name`, ""),
          value: search.province,
        }
      : null,
    canton: search.canton
      ? {
          label: get(
            locations,
            `provinces.${search.province}.cantons.${search.canton}.name`,
            ""
          ),
          value: search.canton,
        }
      : null,
    district: search.district
      ? {
          label: get(
            locations,
            `provinces.${search.province}.cantons.${search.canton}.districts.${search.district}`,
            ""
          ),
          value: search.district,
        }
      : null,
    type: search.type
      ? {
          label: handleTypeTranslate(search.type),
          value: search.type,
        }
      : null,
    amenities: search.amenities
      ? typeof search.amenities === "object"
        ? search.amenities.map((am) => {
            const amAux = amenities.find((ami) => {
              return ami.id === am;
            });
            return {
              label: baseLanguage === "es" ? amAux?.name : amAux?.name_en,
              value: am,
            };
          })
        : [
            (() => {
              const amAux = amenities.find((ami) => {
                return ami.id === search.amenities;
              });
              return {
                label: baseLanguage === "es" ? amAux?.name : amAux?.name_en,
                value: search.amenities,
              };
            })(),
          ]
      : null,
    price:
      search.priceMin && search.priceMax
        ? [Number(search.priceMin), Number(search.priceMax)]
        : [maxMinPrice[0], maxMinPrice[1]],
  };

  const getPropertiesData = async () => {
    toogleIsLoading(true);
    const data: IProperty[] = await getVisibleProperties()!;
    let auxPropertiesList = [...data];
    if (search.sort && typeof search.sort === "string") {
      const sortParams = search.sort.toLowerCase().split("-");
      auxPropertiesList = auxPropertiesList.sort((a, b) => {
        if (sortParams[0] === "price") {
          if (Number(a[sortParams[0]]) < Number(b[sortParams[0]])) {
            return 1;
          }
          if (Number(a[sortParams[0]]) > Number(b[sortParams[0]])) {
            return -1;
          }

          return 0;
        } else if (sortParams[0] === "name") {
          const aName = baseLanguage === "es" ? a.name : a.nameEn;
          const bName = baseLanguage === "es" ? b.name : b.nameEn;
          if (aName > bName) {
            return 1;
          }
          if (aName < bName) {
            return -1;
          }

          return 0;
        }
        return 0;
      });
      if (sortParams[1] === "asc") {
        auxPropertiesList = [...auxPropertiesList].reverse();
      }
    } else {
      auxPropertiesList = auxPropertiesList.sort((a, b) => {
        const aName = baseLanguage === "es" ? a.name : a.nameEn;
        const bName = baseLanguage === "es" ? b.name : b.nameEn;
        if (aName.toUpperCase() > bName.toUpperCase()) {
          return 1;
        }
        if (aName.toUpperCase() < bName.toUpperCase()) {
          return -1;
        }

        return 0;
      });
    }
    setPropertiesList(auxPropertiesList);
    const maxValue =
      max(
        data.map((property: IProperty) => {
          return +property.price;
        })
      ) ?? 0;
    setMaxMinPrice([0, personalRound(maxValue, 1000000)]);

    setPropertiesListFiltered(auxPropertiesList);

    toogleIsLoading(false);
    return auxPropertiesList;
  };

  const sortInfo = (data) => {
    let auxPropertiesList = [...data];
    if (search.sort && typeof search.sort === "string") {
      const sortParams = search.sort.toLowerCase().split("-");
      auxPropertiesList = auxPropertiesList.sort((a, b) => {
        if (sortParams[0] === "price") {
          if (Number(a[sortParams[0]]) < Number(b[sortParams[0]])) {
            return 1;
          }
          if (Number(a[sortParams[0]]) > Number(b[sortParams[0]])) {
            return -1;
          }

          return 0;
        } else if (sortParams[0] === "name") {
          const aName = baseLanguage === "es" ? a.name : a.nameEn;
          const bName = baseLanguage === "es" ? b.name : b.nameEn;
          if (aName > bName) {
            return 1;
          }
          if (aName < bName) {
            return -1;
          }

          return 0;
        }
        return 0;
      });
      if (sortParams[1] === "asc") {
        auxPropertiesList = [...auxPropertiesList].reverse();
      }
    } else {
      auxPropertiesList = auxPropertiesList.sort((a, b) => {
        const aName = baseLanguage === "es" ? a.name : a.nameEn;
        const bName = baseLanguage === "es" ? b.name : b.nameEn;
        if (aName.toUpperCase() > bName.toUpperCase()) {
          return 1;
        }
        if (aName.toUpperCase() < bName.toUpperCase()) {
          return -1;
        }

        return 0;
      });
    }
    return auxPropertiesList;
  };

  const sortLists = () => {
    setPropertiesList(sortInfo(propertiesList));
    setPropertiesListFiltered(sortInfo(propertiesListFiltered));
  };

  const getAmenitiesData = async () => {
    const data: IAmenities[] = await getAmenities(translator);
    setAmenities(data);
  };
  const getFeaturesData = async () => {
    const data: IFeatures[] = await getFeatures(translator);
    setFeatures(data);
  };

  const filterProperties = async (values) => {
    const propertiesList = await getPropertiesData();
    const filteredInformation = propertiesList.filter(
      (property) =>
        filterByKey("nameRef", property, values) &&
        filterByKey("province", property, values) &&
        filterByKey("canton", property, values) &&
        filterByKey("district", property, values) &&
        filterByKey("type", property, values) &&
        filterByKey("amenities", property, values) &&
        filterByKey("price", property, values)
    );
    setPropertiesListFiltered(filteredInformation);
    // navigate(`/properties?${buildOptionsPath(values)}`);
  };

  const filterByKey = (keyName, property: IProperty, values) => {
    if (!values[keyName] || values[keyName] === "") {
      return true;
    }
    if (keyName === "nameRef") {
      const formikValue = values[keyName];
      return (
        property["name"].includes(formikValue) ||
        property["consecutive"] === Number(formikValue.split("-")[1])
      );
    }
    if (keyName === "amenities") {
      const auxIntersection = intersection(
        property[keyName],
        values[keyName].map((am) => {
          return am.value;
        })
      );
      return auxIntersection.length === values[keyName].length;
    }
    if (keyName === "price") {
      if (!(values[keyName][0] && values[keyName][1])) {
        return true;
      }
      return (
        property.price >= values[keyName][0] &&
        property.price <= values[keyName][1]
      );
    }
    return property[keyName] === values[keyName].value;
  };

  const checkRenderPagination = () =>
    propertiesList.length > itemsPerPage &&
    propertiesList.length === filteredCount &&
    filteredCount > itemsPerPage;

  const handleOnPageChange = (pageNumber: number, itemsPerPage: number) => {
    setCurrentPage(pageNumber);
  };

  const items: any = useMemo(() => {
    const auxItems = [...propertiesListFiltered];
    setFilteredCount(auxItems.length);
    if (propertiesList.length !== auxItems.length) {
      return auxItems;
    }
    return parseCurrentPageItems(auxItems, currentPage, itemsPerPage);
  }, [propertiesList, propertiesListFiltered, currentPage, itemsPerPage]);

  const personalRound = (num, scale) => {
    return Math.trunc(num / scale) * scale + scale;
  };
  const buildOptionsPath = (values, sortOpt?) => {
    let path = {};
    if (values.nameRef && values.nameRef !== "") {
      assign(path, { nameRef: values.nameRef });
    }
    if (
      values.price &&
      (values.price[0] > maxMinPrice[0] || values.price < maxMinPrice[1])
    ) {
      assign(path, { priceMin: values.price[0], priceMax: values.price[1] });
    }
    if (values.type && values.type.value) {
      assign(path, { type: values.type.value });
    }
    if (values.amenities && values.amenities?.length > 0) {
      assign(path, {
        amenities: values?.amenities?.map((a) => {
          return a.value;
        }),
      });
    }
    if (values.province && values.province.value) {
      assign(path, { province: values.province.value });
    }
    if (values.canton && values.canton.value) {
      assign(path, { canton: values.canton.value });
    }
    if (values.district && values.district.value) {
      assign(path, { district: values.district.value });
    }
    if (sortOpt) {
      if (sortOpt && sortOpt?.value !== "NAME-DESC") {
        assign(path, { sort: sortOpt.value });
      }
    } else {
      if (sortOption && sortOption.value !== "NAME-DESC") {
        assign(path, { sort: sortOption.value });
      }
    }

    return queryString.stringify(path);
  };

  const SortOptions = () => {
    return (
      <div className="sort-options">
        <FormControl>
          <FormLabel fontWeight={"600"}>
            {translator("Global.sortBy")}
          </FormLabel>

          <Select
            classNamePrefix={"new-select"}
            placeholder={translator("Global.select")}
            value={sortOption}
            getOptionLabel={(option) => `${handleSortTranslate(option.value)}`}
            options={[
              {
                value: "NAME-DESC",
                label: translator("Properties.sortByNameDesc"),
              },
              {
                value: "NAME-ASC",
                label: translator("Properties.sortByNameAsc"),
              },
              {
                value: "PRICE-DESC",
                label: translator("Properties.sortByPriceDesc"),
              },
              {
                value: "PRICE-ASC",
                label: translator("Properties.sortByPriceAsc"),
              },
            ]}
            onChange={(option) => {
              if (isMobile) {
                setIsDrawerOpen(false);
              }
              setSortOption(option);
              navigate(
                `/properties?${buildOptionsPath(ref.current.values, option)}`
              );
            }}
          />
        </FormControl>
      </div>
    );
  };

  useEffect(() => {
    getPropertiesData();
    getAmenitiesData();
    getFeaturesData();
  }, []);
  useEffect(() => {
    filterProperties(initialValues);
  }, [search]);

  useEffect(() => {
    sortLists();
  }, [baseLanguage]);

  return (
    <div className="properties-container">
      <div className="properties-data">
        {items.length > 0 && (
          <div className="properties-header">
            <p className="title">{translator("Properties.title")}</p>
            <p className="description">
              {translator("Properties.description")}
            </p>
          </div>
        )}
        <div className="properties-left-side">
          <div className="sort-filter-options">
            {isMobile && (
              <IconButton
                marginTop={items.length <= 0 ? "20px" : "unset"}
                aria-label=""
                onClick={() => {
                  setIsDrawerOpen(true);
                }}
                icon={<FontAwesomeIcon icon={faFilter} />}
              />
            )}

            {!isMobile && items.length > 0 && <SortOptions />}
          </div>
          {items.length > 0 && (
            <div className="properties-list">
              {items.map((property) => {
                return (
                  <PropertyCard
                    property={property}
                    baseLanguage={baseLanguage}
                    translator={translator}
                    navigate={navigate}
                    action={() => {}}
                    features={features}
                    amenities={amenities}
                  />
                );
              })}
            </div>
          )}
          {items.length <= 0 && (
            <div className="noProperties-container">
              <Img
                width={"200px"}
                src={require("./../../../assets/gifs/glasses.gif")}
              />
              <p className="no-properties-title">
                {translator("Properties.noPropertiesTitle")}
              </p>
              <p className="no-properties-desc">
                {translator("Properties.noPropertiesDesc")}
              </p>
            </div>
          )}
          <div className="pagination-container">
            {checkRenderPagination() && (
              <Pagination
                totalCount={propertiesList.length}
                itemsPerPage={itemsPerPage}
                handleOnPageChange={handleOnPageChange}
                currentPage={currentPage}
                filteredCount={propertiesListFiltered.length}
              />
            )}
          </div>
        </div>
      </div>

      {!isMobile && (
        <div className="side-panel">
          <FiltersForm
            ref={ref}
            initialValues={initialValues}
            baseLanguage={baseLanguage}
            amenities={amenities}
            translator={translator}
            navigate={navigate}
            setIsDrawerOpen={setIsDrawerOpen}
            isMobile={isMobile}
            buildOptionsPath={buildOptionsPath}
            SortOptions={SortOptions}
            maxMinPrice={maxMinPrice}
            provinces={provinces}
            handleTypeTranslate={handleTypeTranslate}
            items={items}
          />
        </div>
      )}
      {isMobile && (
        <Drawer
          showCloseButton
          isOpen={isDrawerOpen}
          onClose={() => {
            setIsDrawerOpen(false);
          }}
        >
          <div className="side-panel-drawer">
            <FiltersForm
              ref={ref}
              initialValues={initialValues}
              baseLanguage={baseLanguage}
              amenities={amenities}
              translator={translator}
              navigate={navigate}
              setIsDrawerOpen={setIsDrawerOpen}
              isMobile={isMobile}
              buildOptionsPath={buildOptionsPath}
              SortOptions={SortOptions}
              maxMinPrice={maxMinPrice}
              provinces={provinces}
              handleTypeTranslate={handleTypeTranslate}
              items={items}
            />
          </div>
        </Drawer>
      )}
    </div>
  );
};

export default Properties;
