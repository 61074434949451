import { useEffect, useState } from "react";
import Modal from "../../layouts/Modal/Modal";
import { IUser } from "../../../types/user";
import { Avatar, Box, Image, Stack } from "@chakra-ui/react";
import { showToast } from "../../../utils/toast";
import "./Profile.scss";
import Input from "../../layouts/Input";
import { Formik } from "formik";
import Button from "../../layouts/Button";
import { updateUser } from "../../../services/UsersService";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import rdmString from "randomstring";
import emailjs from "@emailjs/browser";
import { useAppSelector } from "../../../redux/hooks";
import { decrypt, encrypt } from "../../../utils/encryptUtils";

interface IProfileProps {
  isOpen: boolean;
  onClose: () => void;
  user: IUser;
  translator: any;
  userImage: string;
}
function Profile(props: IProfileProps) {
  const { isOpen, onClose, translator, user, userImage } = props;
  const [userProfileImage, setUserProfileImage] = useState<string>();
  const [userProfileImageFile, setUserProfileImageFile] =
    useState<any>(undefined);

  const navigate = useNavigate();
  const userToken = useAppSelector((state) => state.session.token)!;
  useEffect(() => {
    setUserProfileImage(userImage);
  }, [userImage]);

  const initialValues = {
    name: user.name,
    email: user.email,
    surname: user.surname,
    sSurname: user.sSurname,
    password: user.tempPassword ? user.password : decrypt(user.password),
  };

  const buildUser = (values, isPasswordChanged) => {
    const pass = encrypt(values.password);
    const auxUser: IUser = {
      id: user.id,
      name: values.name,
      surname: values.surname,
      sSurname: values.sSurname,
      email: values.email,
      password: pass,
      document: user.document,
      tempPassword: user.tempPassword && !isPasswordChanged,
    };

    return auxUser;
  };

  const validationSchema = yup.object().shape({
    name: yup.string().required(translator("Global.requieredField")),
    email: yup.string().email(translator("Global.requieredField")),
    surname: yup.string().required(translator("Global.requieredField")),
    sSurname: yup.string().required(translator("Global.requieredField")),
    password: yup
      .string()
      .min(8, translator("Global.passwordMinMssg"))
      .required(translator("Global.requieredField")),
  });

  const getDirtyValues = (values, initialObject) => {
    const data = { ...values };
    const keyValues = Object.keys(data);

    const dirtyValues = keyValues.filter(
      (keyValue) => data[keyValue] !== initialObject[keyValue]
    );

    keyValues.forEach((key) => {
      if (!dirtyValues.includes(key)) delete data[key];
    });

    return data;
  };

  const closeModal = () => {
    onClose();
    setUserProfileImage(userImage);
    setUserProfileImageFile(undefined);
  };

  return (
    <>
      <Modal
        showCloseButton
        isOpen={isOpen}
        onClose={() => {
          closeModal();
        }}
        modalHeader={translator("ProfileDropdown.profile")}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnBlur
          validateOnChange
          enableReinitialize
          onSubmit={async (values) => {
            const dirtyValues = getDirtyValues(values, initialValues);
            if (Object.keys(dirtyValues).length > 0 || !!userProfileImageFile) {
              const isPasswordChanged = dirtyValues.password;
              const pinUser = { ...user };
              pinUser.pin = rdmString.generate(5);
              emailjs.send(
                "service_jxzh4ns",
                "template_7g28l6r",
                {
                  to_name: `${pinUser.name} ${pinUser.surname} ${pinUser.sSurname}`,
                  code: pinUser.pin,
                  send_to: pinUser.email,
                },
                "Ng5-shlzPDNrRGjU9"
              );
              await updateUser(pinUser, translator, false, userToken, navigate);
              const finalUser = buildUser(values, isPasswordChanged);
              navigate("/validatePin", {
                state: {
                  user: user,
                  newUser: finalUser,
                  newImage: userProfileImageFile,
                  newImageURL: userProfileImage,
                },
              });
            }
            onClose();
          }}
        >
          {(props) => {
            return (
              <div>
                <div className="profile-image-container">
                  <div className="profile-pic-div">
                    {!userProfileImage ? (
                      <Avatar
                        className="default-avatar"
                        borderRadius={"0px"}
                        name={`${user.name} ${user.surname}`}
                      />
                    ) : (
                      <Image
                        className="profile-user-image"
                        src={userProfileImage}
                      ></Image>
                    )}
                    <input
                      className="profile-image-picker"
                      type="file"
                      accept="image/jpeg, image/png, image/jpg"
                      id="profile-image-picker"
                      onChange={(e) => {
                        const files = e.target.files;
                        if (files?.length === 1) {
                          setUserProfileImage(URL.createObjectURL(files[0]));
                          setUserProfileImageFile(files[0]);
                        } else {
                          showToast(
                            translator("ToastMessages.warning"),
                            translator("Global.Only1FileAllowed"),
                            "warning"
                          );
                        }
                      }}
                    ></input>
                    <label
                      htmlFor={"profile-image-picker"}
                      onMouseOver={(e) => {}}
                      className="upload-profile-image-label"
                    >
                      {translator("Global.change")}
                    </label>
                  </div>
                </div>
                <Stack spacing="15px">
                  <Box>
                    <Input
                      name="name"
                      label={translator("Global.name")}
                      translator={translator}
                      value={props.values.name}
                      errors={props.errors}
                      validationSchema={validationSchema}
                      onChange={(e) => {
                        props.setFieldValue("name", e.currentTarget.value);
                      }}
                    ></Input>
                  </Box>
                  <Box>
                    <Input
                      name="surname"
                      label={translator("Global.surname")}
                      translator={translator}
                      value={props.values.surname}
                      errors={props.errors}
                      validationSchema={validationSchema}
                      onChange={(e) => {
                        props.setFieldValue("surname", e.currentTarget.value);
                      }}
                    ></Input>
                  </Box>
                  <Box>
                    <Input
                      name="sSurname"
                      label={translator("Global.sSurname")}
                      translator={translator}
                      value={props.values.sSurname}
                      errors={props.errors}
                      validationSchema={validationSchema}
                      onChange={(e) => {
                        props.setFieldValue("sSurname", e.currentTarget.value);
                      }}
                    ></Input>
                  </Box>
                  <Box>
                    <Input
                      name="email"
                      label={translator("Global.email")}
                      translator={translator}
                      value={props.values.email}
                      errors={props.errors}
                      validationSchema={validationSchema}
                      onChange={(e) => {
                        props.setFieldValue("email", e.currentTarget.value);
                      }}
                    ></Input>
                  </Box>
                  <Box>
                    <Input
                      name="password"
                      label={translator("Global.password")}
                      translator={translator}
                      value={props.values.password}
                      type="password"
                      errors={props.errors}
                      validationSchema={validationSchema}
                      onChange={(e) => {
                        props.setFieldValue("password", e.currentTarget.value);
                      }}
                    ></Input>
                  </Box>

                  <Button width="full" onClick={props.submitForm}>
                    {translator("Global.save")}
                  </Button>
                </Stack>
              </div>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
}

export default Profile;
