import { Center, Heading } from "@chakra-ui/react";
import {
  FormControl,
  Flex,
  Stack,
  useColorModeValue,
  HStack,
} from "@chakra-ui/react";
import { PinInput, PinInputField } from "@chakra-ui/react";
import { Formik } from "formik";
import Button from "../../../layouts/Button";
import {
  ValidateCode,
  saveProfilePicture,
  updateUser,
} from "../../../../services/UsersService";
import {
  loginUser,
  loginUserImage,
} from "../../../../redux/reducers/authSlice";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { useLocation, useNavigate } from "react-router-dom";
import useTranslations from "../../../../hooks/useTranslations";

export default function ValidatePin() {
  const location = useLocation();
  const translator = useTranslations();
  const { user, newImage, newUser, newImageURL } = location.state;
  const initialValues = {
    pin: "",
  };

  const flexColor = useColorModeValue("gray.50", "gray.800");
  const stackColor = useColorModeValue("white", "gray.700");
  const centerColor = useColorModeValue("gray.800", "gray.400");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userToken = useAppSelector((state) => state.session.token)!;
  return (
    <Flex minH={"100vh"} align={"center"} justify={"center"} bg={flexColor}>
      <Formik
        initialValues={initialValues}
        onSubmit={async (values) => {
          if (values.pin.length === 5) {
            const validationStatus = await ValidateCode(
              values.pin,
              user.id,
              translator,
              userToken,
              navigate
            );
            if (validationStatus) {
              const status = await updateUser(
                newUser,
                translator,
                true,
                userToken,
                navigate
              );
              status && dispatch(loginUser(newUser));
              if (status && newImage) {
                const imgStatus = await saveProfilePicture(newImage, user.id);

                if (imgStatus) {
                  dispatch(loginUserImage(newImageURL));
                }
              }
              const removePinUser = { ...user };
              removePinUser.pin = "";
              navigate("/adm/properties");
            }
          }
        }}
      >
        {(props) => (
          <Stack
            spacing={4}
            w={"full"}
            maxW={"sm"}
            bg={stackColor}
            rounded={"xl"}
            boxShadow={"lg"}
            p={6}
            my={10}
          >
            <Center>
              <Heading lineHeight={1.1} fontSize={{ base: "2xl", md: "3xl" }}>
                {translator("ValidatePin.verifyEmail")}
              </Heading>
            </Center>
            <Center fontSize={{ base: "sm", sm: "md" }} color={centerColor}>
              {translator("ValidatePin.sentCodeEmail")}
            </Center>
            <Center
              fontSize={{ base: "sm", sm: "md" }}
              fontWeight="bold"
              color={centerColor}
            >
              {user.email}
            </Center>
            <FormControl>
              <Center>
                <HStack>
                  <PinInput
                    type="alphanumeric"
                    onChange={(value) => {
                      props.setFieldValue("pin", value);
                    }}
                  >
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                  </PinInput>
                </HStack>
              </Center>
            </FormControl>
            <Stack spacing={6}>
              <Button onClick={props.submitForm}>
                {translator("ValidatePin.verify")}
              </Button>
            </Stack>
          </Stack>
        )}
      </Formik>
    </Flex>
  );
}
