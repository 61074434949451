import React, { ReactElement } from "react";
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  DrawerProps,
} from "@chakra-ui/react";

interface IDrawerProps extends DrawerProps {
  showCloseButton?: boolean;
  modalHeader?: ReactElement | string;
}

function CCDrawer(props: IDrawerProps) {
  const firstField = React.useRef<any>();
  const {
    isOpen,
    onClose,
    children,
    showCloseButton,
    modalHeader,
    placement = "right",
    size = "md",
  } = props;

  return (
    <Drawer
      isOpen={isOpen}
      initialFocusRef={firstField}
      onClose={onClose}
      placement={placement}
      size={size}
    >
      <DrawerOverlay />
      <DrawerContent>
        {showCloseButton && <DrawerCloseButton />}
        {modalHeader && (
          <DrawerHeader borderBottomWidth="1px">{modalHeader}</DrawerHeader>
        )}

        <DrawerBody>{children}</DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

export default CCDrawer;
