import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Avatar,
  Button,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuItemProps,
  MenuList,
  Stack,
} from "@chakra-ui/react";
import {
  faAngleDown,
  faLanguage,
  // faMoon,
  faRightFromBracket,
  // faSun,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./ProfileDropdown.scss";
import { IUser } from "../../../types/user";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { logout } from "../../../providers/authContextProvider";
import Profile from "../../views/Profile";
import { useRef, useState } from "react";
// import { useColorMode } from "@chakra-ui/react";
// import { updateUser } from "../../../services/UsersService";
// import { useNavigate } from "react-router-dom";
import { changeApplanguage } from "../../../redux/reducers/appSettingsSlice";
import useIsMobile from "../../../hooks/useIsMobile";
import { useNavigate } from "react-router-dom";
// import { loginUser } from "../../../redux/reducers/authSlice";

interface IProfileDD {
  translator: any;
  user: IUser;
}

function ProfileDropdown(props: IProfileDD) {
  const dispatch = useAppDispatch();
  const { translator, user } = props;
  const userImage: string = useAppSelector((state) => state.session.userImage)!;
  const [isOpen, setIsOpen] = useState(false);
  const [isPasswordAlertOpen, setIsPasswordAlertOpen] = useState(
    user.tempPassword!
  );
  // const { colorMode, toggleColorMode } = useColorMode();
  // const isLightMode = colorMode === "light";
  // const navigate = useNavigate();
  // const userToken = useAppSelector((state) => state.session.token)!;
  const [baseLanguage, setBaseLanguage] = useState(
    useAppSelector((state) => state.appSettings.language)
  );
  const cancelRef = useRef<any>();
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  interface ICustomMunuItemsProps extends MenuItemProps {
    icon: any;
    label: string;
  }
  function CustomMenuItem({ icon, label, onClick }: ICustomMunuItemsProps) {
    return (
      <MenuItem onClick={onClick}>
        <Stack className="option-base" direction={"row"}>
          {icon}
          <label>{label}</label>
        </Stack>
      </MenuItem>
    );
  }

  function toggleIsOpen() {
    setIsOpen(!isOpen);
  }

  const onLanguageChange = async (language) => {
    setBaseLanguage(language);
    // const auxUser = { ...user };
    // auxUser.language = language;
    // const status = await updateUser(
    //   auxUser,
    //   translator,
    //   false,
    //   userToken,
    //   navigate
    // );
    // status &&
    dispatch(changeApplanguage(language));
  };

  return (
    <>
      <AlertDialog
        isCentered
        isOpen={isPasswordAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => {
          setIsPasswordAlertOpen(false);
        }}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {translator("ProfileDropdown.changePasswordTitle")}
            </AlertDialogHeader>

            <AlertDialogBody>
              {translator("ProfileDropdown.changePasswordMessage")}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                variant={"solid"}
                colorScheme="red"
                ref={cancelRef}
                onClick={() => {
                  setIsPasswordAlertOpen(false);
                }}
              >
                {translator("Global.cancel")}
              </Button>
              <Button
                colorScheme="green"
                onClick={async () => {
                  setIsPasswordAlertOpen(false);
                  setIsOpen(true);
                }}
                ml={3}
              >
                {translator("ProfileDropdown.changePassword")}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <Profile
        user={user}
        translator={translator}
        isOpen={isOpen}
        userImage={userImage}
        onClose={() => {
          toggleIsOpen();
        }}
      ></Profile>
      <Menu>
        <MenuButton>
          <Stack className="visual-profile-base" direction={"row"}>
            {!userImage ? (
              <Avatar name={`${user.name} ${user.surname}`} size={"md"} />
            ) : (
              <Image
                boxSize={"48px"}
                borderRadius="full"
                src={userImage}
              ></Image>
            )}
            <label className="user-Name">{`${user.name} ${user.surname}`}</label>
            <FontAwesomeIcon color="#fff" icon={faAngleDown} />
          </Stack>
        </MenuButton>
        <MenuList className="menu-list">
          <CustomMenuItem
            icon={<FontAwesomeIcon icon={faUser} />}
            label={translator("ProfileDropdown.profile")}
            onClick={() => setIsOpen(true)}
          />
          {/* <CustomMenuItem
            icon={
              colorMode === "light" ? (
                <FontAwesomeIcon icon={faMoon} />
              ) : (
                <FontAwesomeIcon icon={faSun} />
              )
            }
            label={translator("ProfileDropdown.changeTheme")}
            onClick={async () => {
              const auxUser = { ...user };
              // auxUser.defaultTheme = isLightMode ? "dark" : "light";
              const status = await updateUser(
                auxUser,
                translator,
                false,
                userToken,
                navigate
              );
              dispatch(loginUser(auxUser));
              status && toggleColorMode();
            }}
          /> */}
          {isMobile && (
            <CustomMenuItem
              icon={<FontAwesomeIcon icon={faLanguage} />}
              label={translator("ProfileDropdown.toggleLanguage")}
              onClick={async () => {
                onLanguageChange(baseLanguage === "es" ? "en" : "es");
              }}
            />
          )}
          <CustomMenuItem
            icon={<FontAwesomeIcon icon={faRightFromBracket} />}
            label={translator("ProfileDropdown.logout")}
            onClick={() => {
              logout(dispatch);
              navigate("/login");
            }}
          />
        </MenuList>
      </Menu>
    </>
  );
}

export default ProfileDropdown;
