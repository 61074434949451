import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import IProperty from "../../types/property";

interface ISettingsState {
  language: string;
  translations: {};
  isLoading: boolean;
  isMapLoaded: boolean;
  favoritesProperties: IProperty[];
}

const initialState: ISettingsState = {
  language: "es",
  translations: {},
  isLoading: false,
  isMapLoaded: false,
  favoritesProperties: [],
};

export const appSettingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    changeApplanguage: (state, { payload }: PayloadAction<string>) => {
      state.language = payload;
    },
    changeAppTranslations: (state, { payload }: PayloadAction<{}>) => {
      state.translations = payload;
    },
    changeIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    changeIsMapLoaded: (state, { payload }: PayloadAction<boolean>) => {
      state.isMapLoaded = payload;
    },
    changeFavoritesProperties: (
      state,
      { payload }: PayloadAction<IProperty[]>
    ) => {
      state.favoritesProperties = payload;
    },
    addFavoritesProperties: (state, { payload }: PayloadAction<IProperty>) => {
      state.favoritesProperties = [...state.favoritesProperties, payload];
    },
  },
});

export const {
  changeApplanguage,
  changeAppTranslations,
  changeIsLoading,
  changeIsMapLoaded,
  changeFavoritesProperties,
  addFavoritesProperties,
} = appSettingsSlice.actions;

export default appSettingsSlice.reducer;
